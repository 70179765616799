import React, { forwardRef } from 'react';
import { Form, Input, Select, Button } from 'antd';

const { TextArea } = Input;

const OrganizationForm = forwardRef(({ editingOrganization, onFinish, users }, ref) => (
  <Form form={ref} layout="vertical" onFinish={onFinish}>
    <Form.Item
      name="name"
      label="Organization Name"
      rules={[{ required: true, message: 'Please input the organization name!' }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="description"
      label="Description"
      rules={[{ required: true, message: 'Please input the description!' }]}
    >
      <TextArea rows={4} />
    </Form.Item>
    <Form.Item
      name="contact_email"
      label="Contact Email"
      rules={[
        { required: true, message: 'Please input the contact email!' },
        { type: 'email', message: 'Please enter a valid email!' },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="contact_phone"
      label="Contact Phone"
      rules={[
        { required: true, message: 'Please input the contact phone!' },
        { pattern: /^\+?\d{10,15}$/, message: 'Please enter a valid phone number!' },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="user_id"
      label="User"
      rules={[{ required: true, message: 'Please select or input a user!' }]}
    >
      <Select
        showSearch
        placeholder="Select or input a user"
        optionFilterProp="children"
        allowClear
      >
        {users.map((user) => (
          <Select.Option key={user._id} value={user._id}>
            {user.full_name} ({user.email})
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item
      name="website"
      label="Website URL"
      rules={[{ type: 'url', message: 'Please enter a valid URL!' }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="address"
      label="Address"
      rules={[{ max: 300, message: 'Address cannot exceed 300 characters!' }]}
    >
      <TextArea rows={2} />
    </Form.Item>
    <Form.Item>
      <Button type="primary" htmlType="submit" block>
        {editingOrganization ? 'Update Organization' : 'Add Organization'}
      </Button>
    </Form.Item>
  </Form>
));

export default OrganizationForm;
