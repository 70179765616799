import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import AuthService from '../../services/AuthService';
import { Button, ConfigProvider, Form, Input, notification } from 'antd';

function ResetPassword() {
  const [form] = Form.useForm(); // Ant Design form instance
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // const [searchParams] = useSearchParams();

  // Get the current URL
const url = new URL(window.location.href);

// Get the token from the URL query parameters
const token = url.searchParams.get('token');
  // // Extract token from query parameters
  // const token = searchParams.get('token');

  useEffect(() => {
    if (!token) {
      navigate('/'); // Redirect to home if no token
    } else {
      try {
        // Decode token
        const decoded = jwtDecode(token);

        // Check token expiration
        const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds
        if (decoded.exp && currentTime > decoded.exp) {
          notification.error({
            message: 'Error',
            description: 'Token has expired',
          });
          navigate('/NotFound');
        }
      } catch (err) {
        console.error('Invalid token:', err);
        notification.error({
          message: 'Error',
          description: 'Invalid or expired token',
        });
        navigate('/NotFound');
      }
    }
  }, [token, navigate]);

  const handleSubmit = async (values) => {
    const { newPassword, confirmPassword } = values;

    if (newPassword !== confirmPassword) {
      notification.error({
        message: "Passwords Don't Match",
        description: 'Please ensure both passwords match.',
      });
      return;
    }

    setLoading(true);

    try {
      console.log("tokEEN", token);
      
      await AuthService.resetPassword(token, newPassword); // Assuming AuthService has a resetPassword function
      notification.success({
        message: 'Success',
        description: 'Password reset successfully!',
      });
      setTimeout(() => navigate('/login'), 2000); // Redirect to login after success
    } catch (err) {
      notification.error({
        message: 'Error',
        description: err?.message || 'Failed to reset password. Please try again.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            primaryColor: 'Black',
          }, Form: {
            itemMarginBottom: "8px",

          }
        },
      }}
    >
      <div className="main-container">
        <h1>Reset Password</h1>
        <Form
          form={form}
          name="resetPasswordForm"
          layout="vertical"
          autoComplete="off"
          onFinish={handleSubmit} // Trigger on successful form submission
        >
          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              {
                required: true,
                message: 'Please enter your new password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Confirm New Password"
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: 'Please confirm your new password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              block
              loading={loading}
            >
              {loading ? 'Resetting...' : 'Reset Password'}
            </Button>
          </Form.Item>
        </Form>
        <p className="footer-text">
          Remembered your password? <Link to="/login">Login here</Link>
        </p>
      </div>
    </ConfigProvider>
  );
}

export default ResetPassword;
