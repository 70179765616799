import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";

import { Typography, message } from "antd";
import List from "../../components/List/List";
import BookingService from "../../services/BookingService";
import "../adventure/Adventure.css";
// import LocalStorageService from "../../services/LocalStorage";

const { Title, Text } = Typography;

const CustomLoader = () => (
  <div className="custom-loader-container">
    <div
      style={{
        width: "100px",
        height: "100px",
        animation: "spinAnimation 1.5s linear infinite",
        backgroundImage: `url('/images/WB-Logo-Gif.gif')`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
    ></div>
  </div>
);

const MyBookings = () => {
  //   const navigate = useNavigate();
  //   const token = LocalStorageService.getItem("token");

  const [bookings, setBookings] = useState([]); // State to hold user bookings
  const [loading, setLoading] = useState(false); // State for loading indicator

  // Fetch user bookings when the component loads
  useEffect(() => {
    fetchBookings();
  }, []);

  // Fetch bookings from the server
  const fetchBookings = async () => {
    setLoading(true); // Show loader
    try {
      const data = await BookingService.getUserBookings();

      // Map the response to initialize the booking state
      const userBookings = (data.data || []).map((booking) => ({
        ...booking.ad, // Assuming "ad" contains related booking details
        bookingDate: booking.booking_date,
        status: booking?.status,
        payment_status: booking.payment_status
      }));
      setBookings(userBookings); // Update state with fetched bookings
    } catch (error) {
      message.error("Failed to fetch bookings. Please try again.");
      console.error("Error fetching bookings:", error);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  // Render loading state
  if (loading) {
    return (
      <div className="loading-container">
        <CustomLoader />
      </div>
    );
  }

  // Render empty state if no bookings
  if (bookings.length === 0) {
    return (
      <div className="loading-container">
        <Text>No bookings found for the user.</Text>
      </div>
    );
  }

  // Render list of user bookings
  return (
    <div className="container">
      <div className="navbar"></div>
      <div className="header">
        <Title className="title" level={2}>
          Your Bookings
        </Title>
        <Text type="secondary" className="subTitle">
          View and manage your bookings.
        </Text>
      </div>
      <div className="listContainer">
        <List items={bookings} />
      </div>
    </div>
  );
};

export default MyBookings;
