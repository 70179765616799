import React, { useState } from "react";
import { List as AntdList, Tag, Image, Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { HeartFilled, HeartOutlined } from "@ant-design/icons";
import "./List.css";
import { CalendarOutlined } from "@ant-design/icons";

const { Text } = Typography;

const List = ({ items, onToggleFavorite }) => {
  console.log("GOT THESE IN BOOKING", items)
  const navigate = useNavigate();

  // Initialize loading state for each image
  const [loadingStates, setLoadingStates] = useState(
    items.map(() => true) // All items start as loading
  );

  const handleImageLoad = (index) => {
    setLoadingStates((prev) => {
      const newStates = [...prev];
      newStates[index] = false;
      return newStates;
    });
  };

  const handleImageError = (index) => {
    setLoadingStates((prev) => {
      const newStates = [...prev];
      newStates[index] = false;
      return newStates;
    });
  };

  const handleViewDetails = (adId) => {
    navigate(`/adventures/detail?search=${adId}`);
  };

  const renderSkeletonOrImage = (index, src, alt) => (
    <div style={{ position: "relative", width: "100%", height: "16.7rem" }}>
      {loadingStates[index] && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            animation: "bgColorAnimation 3s infinite",
            backgroundImage: `url('/images/WB-Logo-Gif.gif')`,
            backgroundSize: "90px",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            borderRadius: "8px",
          }}
        ></div>
      )}
      <Image
        alt={alt}
        src={src}
        style={{
          borderRadius: "12px",
          display: loadingStates[index] ? "none" : "block",
        }}
        placeholder={null}
        fallback="/images/11.jpg"
        onLoad={() => handleImageLoad(index)}
        onError={() => handleImageError(index)}
        width="100%"
        height="100%"
      />
    </div>
  );

  return (
    <AntdList
      grid={{
        gutter: 24,
        xs: 1,
        sm: 1,
        md: 2,
        lg: 2,
        xl: 3,
        xxl: 4,
      }}
      dataSource={items}
      renderItem={(item, index) => (
        <AntdList.Item>
          <div className="custom-card">
            <div className="image-container">
              {renderSkeletonOrImage(
                index,
                item.images.length > 0 ? item.images[0] : "/images/11.jpg",
                item.title
              )}
              <div className="flex-between a-top">
                <h3 className="cardTitle">{item.title}</h3>
                <div className="tag">
                  <CalendarOutlined className="calendar-icon" />
                  <Text className="date-text">
                    {new Date(item.start_date).toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "short",
                    })}{" "}
                    -{" "}
                    {new Date(item.end_date).toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "short",
                    })}
                  </Text>
                </div>
              </div>
            </div>
            <div className="info">
              <div className="price flex-between">
                <p color="green">PKR {item.price.toLocaleString()}</p>
                <Tag color="blue">{item.duration}</Tag>
              </div>
              <p className="cardDescription">{item.description}</p>
            </div>
            <div className="cardFooter">
              {item?.status && (
                <div>
                  <div
                    className={`booking-status ${item.status === 'Accepted'
                      ? 'success'
                      : item.status === 'Rejected' || item.status === 'Cancelled'
                        ? 'danger'
                        : item.status === 'Open'
                          ? 'warning'
                          : ''
                      }`}
                  >
                    {item.status === 'Open' ? 'Waiting for approval' : item.status}
                  </div>

                </div>
              )}
              {onToggleFavorite && (
                <div
                  onClick={() => onToggleFavorite(item._id)}
                  style={{ cursor: "pointer" }}
                >
                  {item.isFavorited ? (
                    <HeartFilled style={{ color: "red", fontSize: 28, padding: "0 6px" }} />
                  ) : (
                    <HeartOutlined style={{ fontSize: 28, padding: "0 6px" }} />
                  )}
                </div>
              )}
              <Button
                type="primary"
                shape="round"
                onClick={() => handleViewDetails(item._id)}
              >
                View Details
              </Button>

            </div>
          </div>
        </AntdList.Item>
      )}
    />
  );
};

export default List;
