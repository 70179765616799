import React, { useState } from 'react';
import { Row, Col, Image, Carousel, Modal, Button, ConfigProvider } from 'antd';

const ImageGallery = ({ images, title }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingStates, setLoadingStates] = useState(images.map(() => true));
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;

  const handleOpenModal = () => setIsModalVisible(true);
  const handleCloseModal = () => setIsModalVisible(false);

  const handleImageLoad = (index) => {
    setLoadingStates((prev) => {
      const newStates = [...prev];
      newStates[index] = false;
      return newStates;
    });
  };

  const handleImageError = (index) => {
    setLoadingStates((prev) => {
      const newStates = [...prev];
      newStates[index] = false;
      return newStates;
    });
  };

  const contentStyle = {
    objectFit: "contain",
    background: "var(--input-bg)",
  };

  const renderCarousel = (height) => (
    <ConfigProvider
      theme={{
        components: { Carousel: { arrowSize: 32, dotHeight: 6 } },
      }}
    >
      {/* autoplay autoplaySpeed={4000} */}
      <Carousel arrows autoplay autoplaySpeed={4000}>
        {images.map((img, index) => (
          <div key={index} style={{ position: "relative", height }}>
            <div style={{ position: "relative", width: "100%", height }}>
              {loadingStates[index] && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    animation: "bgColorAnimation 3s infinite",
                    backgroundImage: `url('/images/WB-Logo-Gif.gif')`,
                    backgroundSize: "90px",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    borderRadius: "8px",

                  }}
                ></div>
              )}
              <Image
                style={{
                  ...contentStyle,
                  display: loadingStates[index] ? "none" : "block",
                }}
                alt={`${title} ${index + 1}`}
                src={img || "/images/11.jpg"}
                placeholder={null}
                fallback="/images/11.jpg"
                width="100%"
                height={height}
                onLoad={() => handleImageLoad(index)}
                onError={() => handleImageError(index)}
              />
            </div>
          </div>
        ))}
      </Carousel>
    </ConfigProvider>
  );

  const renderSkeletonOrImage = (index, src, height, alt) => (
    <div style={{ position: "relative", width: "100%", height }}>
      {loadingStates[index] && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            animation: "bgColorAnimation 3s infinite",
            backgroundImage: `url('/images/WB-Logo-Gif.gif')`,
            backgroundSize: "90px",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            borderRadius: "8px",
          }}
        ></div>
      )}
      <Image
        style={{
          objectFit: "cover",
          display: loadingStates[index] ? "none" : "block",
        }}
        alt={alt}
        src={src}
        placeholder={null}
        fallback="/images/11.jpg"
        width="100%"
        height={height}
        onLoad={() => handleImageLoad(index)}
        onError={() => handleImageError(index)}
      />
    </div>
  );
  if (windowWidth < 800) {
    return <>{renderCarousel('35rem')}</>;
  }
  return (
    <>
      {images?.length > 5 ? (
        <Row gutter={0} style={{ width: "100%", height: "35rem", position: "relative" }}>
          {/* First Column: Single Image */}
          <Col span={12} style={{ padding: 0, height: "100%" }}>
            {renderSkeletonOrImage(0, images[0] || "/images/11.jpg", "100%", title)}
          </Col>

          {/* Second Column: Four Equal Images */}
          <Col span={12} style={{ padding: 0, height: "100%" }}>
            <Row gutter={0} style={{ width: "100%", height: "100%" }}>
              {images.slice(1, 5).map((img, index) => (
                <Col span={12} key={index} style={{ padding: 0, height: "50%" }}>
                  {renderSkeletonOrImage(
                    index + 1,
                    img || "/images/11.jpg",
                    "100%",
                    `${title} ${index + 2}`
                  )}
                </Col>
              ))}
            </Row>
          </Col>

          <Button
            type="primary"
            onClick={handleOpenModal}
            style={{ margin: "2rem", position: "absolute", bottom: 0, right: 0 }} /* Adjust button placement */
          >
            View All Photos
          </Button>

        </Row>


      ) : (
        <>
          {renderCarousel('35rem')}
        </>

      )}

      {/* Fullscreen Modal with Carousel */}
      <Modal
        open={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
        centered
        width="100%"
        height={windowHeight - 40}

      >
        <div style={{ margin: "1.5rem 0" }}>
          {renderCarousel(windowHeight - 120)}
        </div>

      </Modal>
    </>
  );
};

export default ImageGallery;