import React, { forwardRef, useEffect, useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  DatePicker,
  Select,
  Button,
  Checkbox,
  Divider,
  Upload,
  Image,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const { TextArea } = Input;
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const AdForm = forwardRef(
  (
    { editingAd, onFinish, cities, organizations, fullPage, onBack, loading },
    ref
  ) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
      if (editingAd?.images) {
        setFileList(
          editingAd.images.map((url) => ({
            url,
          }))
        );
      } else {
        setFileList([]);
      }
    }, [editingAd]);

    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) =>
      setFileList(newFileList);

    // Calculate days and nights
    const calculateDuration = () => {
      const startDate = ref.getFieldValue("start_date");
      const endDate = ref.getFieldValue("end_date");

      if (startDate && endDate) {
        const diffInMilliseconds = dayjs(endDate).diff(dayjs(startDate));
        const totalDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24)); // Convert ms to days
        const totalNights = totalDays > 0 ? totalDays - 1 : 0;

        const duration = `${totalDays} Days, ${totalNights} Nights`;
        ref.setFieldsValue({ duration });
        ref.setFieldsValue({ itinerary: [] });

        // Add new itinerary fields based on totalDays
        const itineraryItems = Array.from(
          { length: totalDays },
          (_, index) => ({
            day: index + 1,
            activities: "",
          })
        );
        ref.setFieldsValue({ itinerary: itineraryItems });
      }
    };

    return (
      <div>
        {fullPage && (
          <Button
            type="default"
            onClick={onBack}
            style={{ marginBottom: "20px" }}
          >
            Back to List
          </Button>
        )}
        <Form
          form={ref}
          layout="vertical"
          onFinish={(values) => onFinish({ ...values, images: fileList })}
        >
          <Form.Item
            label="Upload Images"
            name="images"
            valuePropName="fileList"
          >
            <Upload
              multiple={true}
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
              beforeUpload={() => false}
            >
              {fileList.length >= 10 ? null : <PlusOutlined />}
            </Upload>
            {previewImage && (
              <Image
                wrapperStyle={{ display: "none" }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(""),
                }}
                src={previewImage}
              />
            )}
          </Form.Item>
          <Form.Item
            name="title"
            label="Ad Title"
            rules={[{ required: true, message: "Please input the ad title!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Please input the description!" },
              {
                validator: (_, value) => {
                  if (!value) return Promise.resolve();
                  const wordCount = value.trim().split(/\s+/).length;
                  if (wordCount < 10) {
                    return Promise.reject(
                      new Error("Description must be at least 10 words!")
                    );
                  }
                  if (wordCount > 2000) {
                    return Promise.reject(
                      new Error("Description cannot exceed 2000 characters!")
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <TextArea rows={4} maxLength={2000} showCount />
          </Form.Item>

          <Form.Item
            name="price"
            label="Price"
            rules={[{ required: true, message: "Please input the price!" }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              min={0}
              placeholder="Enter price"
            />
          </Form.Item>

          <Form.Item
            name="start_date"
            label="Start Date"
            rules={[
              { required: true, message: "Please select the start date!" },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              format="YYYY-MM-DD HH:mm A"
              showTime
              use12Hours
              onChange={calculateDuration}
            />
          </Form.Item>

          <Form.Item
            name="end_date"
            label="End Date"
            rules={[{ required: true, message: "Please select the end date!" }]}
          >
            <DatePicker
              style={{ width: "100%" }}
              format="YYYY-MM-DD HH:mm A"
              showTime
              use12Hours
              onChange={calculateDuration}
            />
          </Form.Item>

          <Form.Item
            name="duration"
            label="Duration"
            rules={[{ required: true, message: "Please input the duration!" }]}
          >
            <Input readOnly />
          </Form.Item>

          <Form.Item
            name="cities_covered"
            label="Cities Covered"
            rules={[
              { required: true, message: "Please select at least one city!" },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Search and select cities"
              allowClear
              showSearch
              optionFilterProp="children"
            >
              {cities?.map((city) => (
                <Select.Option key={city._id} value={city._id}>
                  {city.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="organization"
            label="Organization"
            rules={[
              { required: true, message: "Please select an organization!" },
            ]}
          >
            <Select
              showSearch
              placeholder="Select an organization"
              optionFilterProp="children"
              allowClear
            >
              {organizations?.map((org) => (
                <Select.Option key={org._id} value={org._id}>
                  {org.name} ({org.contact_email})
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="meeting_point" label="Meeting Point">
            <Input placeholder="Enter meeting point (optional)" />
          </Form.Item>

          <Form.Item name="departure" label="Departure">
            <Input placeholder="Enter departure location (optional)" />
          </Form.Item>

          <Form.Item name="return_location" label="Return Location">
            <Input placeholder="Enter return location (optional)" />
          </Form.Item>

          <Form.Item name="price_includes" label="Price Includes">
            <TextArea
              rows={2}
              maxLength={1000}
              placeholder="List what the price includes, separated by commas (optional)"
            />
          </Form.Item>

          <Form.Item name="price_excludes" label="Price Excludes">
            <TextArea
              rows={2}
              maxLength={1000}
              placeholder="List what the price excludes, separated by commas (optional)"
            />
          </Form.Item>

          <Form.Item name="additional_services" label="Additional Services">
            <TextArea
              rows={2}
              maxLength={1000}
              placeholder="Add any additional services offered (optional)"
            />
          </Form.Item>

          <Form.Item name="transportation_type" label="Transportation Type">
            <Select placeholder="Select transportation type" allowClear>
              <Select.Option value="Bus">Bus</Select.Option>
              <Select.Option value="Train">Train</Select.Option>
              <Select.Option value="Car">Car</Select.Option>
              <Select.Option value="Airplane">Airplane</Select.Option>
            </Select>
          </Form.Item>

          <Divider>Hotels</Divider>
          <Form.List name="hotels">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} style={{ display: "flex", gap: "8px" }}>
                    <Form.Item
                      {...restField}
                      name={[name, "city"]}
                      label="City"
                      rules={[{ required: true, message: "City is required!" }]}
                    >
                      <Input placeholder="Enter city name" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "hotel"]}
                      label="Hotel"
                      rules={[
                        { required: true, message: "Hotel is required!" },
                      ]}
                    >
                      <Input placeholder="Enter hotel name" />
                    </Form.Item>
                    <Button type="dashed" danger onClick={() => remove(name)}>
                      Remove
                    </Button>
                  </div>
                ))}
                <Button type="dashed" onClick={() => add()} block>
                  Add Hotel
                </Button>
              </>
            )}
          </Form.List>

          

          <Form.Item name="contact_information" label="Contact Information">
            <Input.Group>
              <Form.Item
                name={["contact_information", "phone"]}
                rules={[
                  {
                    max: 15,
                    message: "Phone number cannot exceed 15 characters!",
                  },
                ]}
              >
                <Input placeholder="Phone Number (optional)" />
              </Form.Item>
              <Form.Item
                name={["contact_information", "email"]}
                rules={[
                  {
                    type: "email",
                    message: "Please input a valid email address!",
                  },
                ]}
              >
                <Input placeholder="Email Address (optional)" />
              </Form.Item>
            </Input.Group>
          </Form.Item>

          <Divider>Itinerary</Divider>
          <Form.List name="itinerary">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} style={{ display: "flex", gap: "8px" }}>
                    <Form.Item
                      {...restField}
                      name={[name, "day"]}
                      label="Day"
                      rules={[
                        { required: true, message: "Day is required!" },
                        {
                          type: "number",
                          min: 1,
                          message: "Day must be at least 1!",
                        },
                      ]}
                    >
                      <InputNumber min={1} placeholder="Day" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "title"]}
                      label="Title"
                      rules={[
                        { required: true, message: "Title is required!" },
                        {
                          max: 100,
                          message: "Title cannot exceed 100 characters!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter title for this day" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "activities"]}
                      label="Activities"
                      rules={[
                        { required: true, message: "Activities are required!" },
                        {
                          max: 1000,
                          message: "Activities cannot exceed 1000 characters!",
                        },
                      ]}
                    >
                      <TextArea
                        rows={2}
                        placeholder="Enter activities for the day"
                      />
                    </Form.Item>
                    <Button type="dashed" danger onClick={() => remove(name)}>
                      Remove
                    </Button>
                  </div>
                ))}
                <Button type="dashed" onClick={() => add()} block>
                  Add Day
                </Button>
              </>
            )}
          </Form.List>

          <Form.Item name="maximum_participants" label="Maximum Participants">
            <InputNumber
              style={{ width: "100%" }}
              min={1}
              placeholder="Enter maximum participants"
            />
          </Form.Item>

          <Form.Item
            name="is_featured"
            valuePropName="checked"
            label="Featured Ad"
          >
            <Checkbox>Mark as Featured</Checkbox>
          </Form.Item>

          <Form.Item name="is_active" valuePropName="checked" label="Active">
            <Checkbox>Is Active</Checkbox>
          </Form.Item>

          <Form.Item name="highlights" label="Highlights">
            <TextArea
              rows={2}
              maxLength={500}
              placeholder="Add highlights (optional)"
            />
          </Form.Item>

          <Form.Item name="tags" label="Tags">
            <Input placeholder="Enter comma-separated tags" />
          </Form.Item>

          <Form.Item name="terms_and_conditions" label="Terms and Conditions">
            <TextArea
              rows={4}
              maxLength={3000}
              placeholder="Enter terms and conditions"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              {editingAd ? "Update Ad" : "Add Ad"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
);

export default AdForm;
