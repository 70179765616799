import React, { forwardRef } from 'react';
import { Form, Input, Select, Button } from 'antd';

const UserForm = forwardRef(({ editingUser, onFinish }, ref) => (
  <Form form={ref} layout="vertical" onFinish={onFinish}>
    <Form.Item
      name="first_name"
      label="First Name"
      rules={[{ required: true, message: 'Please input the first name!' }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="last_name"
      label="Last Name (Optional)"
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="email"
      label="Email"
      rules={[
        { required: true, message: 'Please input the email!' },
        { type: 'email', message: 'Please input a valid email!' },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="role"
      label="Role"
      rules={[
        { required: true, message: 'Please select a role!' },
      ]}
    >
      <Select placeholder="Select a role">
        <Select.Option value="USER">User</Select.Option>
        <Select.Option value="ORGANIZATION">Organization</Select.Option>
      </Select>
    </Form.Item>
    {!editingUser && (
      <Form.Item
        name="password"
        label="Password"
        rules={[{ required: true, message: 'Please input the password!' }]}
      >
        <Input.Password />
      </Form.Item>
    )}
    <Form.Item>
      <Button type="primary" htmlType="submit" block>
        {editingUser ? 'Update User' : 'Add User'}
      </Button>
    </Form.Item>
  </Form>
));

export default UserForm;
