import React, { useEffect, useMemo, useCallback, useState } from 'react';
import "./Auth.css"
import { Outlet } from 'react-router-dom';

const AuthLayout = () => {

    const codingWords = useMemo(() => [
        "Gilgit", "Skardu", "Hunza", "Chitral", "Swat", "Abbottabad", "Nathia Gali", "Malam Jabba", "Dir", "Kaghan",
        "Naran", "Murree", "Muzaffarabad", "Balakot", "Bagh", "Azad Jammu", "Neelum Valley", "Rawalakot", "Upper Dir", "Kohistan",
        "Babusar", "Shogran", "Fairy Meadows", "Astore", "Ghizer", "Sakardu", "Diamer", "Khunjerab", "Thandiani", "Ratti Gali",
        "Shounter", "Kumrat", "Batakundi", "Sharda", "Kel", "Taobat", "Kandol Lake", "Rama Meadows", "Saidu Sharif", "Chilas",
        "Haripur", "Gojal", "Tarbela", "Pattan", "Aliabad", "Jaglot", "Mansehra", "Ghanche", "Phander", "Khaplu",
        "Besham", "Karimabad", "Dasu", "Sost", "Misgar", "Bunji", "Gupis", "Passu", "Tashkurgan", "Darel",
        "Haramosh", "Kargah Valley", "Naltar", "Rupin", "Astor", "Karkorum", "Chapursan", "Attabad", "Chunda Valley", "Chilas Top",
        "Rupin Pass", "Baltit", "Khaplu Fort", "Upper Kachura", "Lower Kachura", "Ziarat", "Chiniot", "Sakhi Sarwar", "Gwadar", "Ormara",
        "Pasni", "Sost Market", "Shimshal", "Borith Lake", "Yasin Valley", "Shishkat", "Minapin", "Morkhun", "Qasimabad", "Nagar Valley",
        "Skardu Valley", "Gulmit", "Ghulkin", "Mashabrum", "Rakaposhi", "Mirpur", "Ratti Gali", "Gurez", "Deosai Plains", "Tarar Khel"
    ], []);
    const [usedWords, setUsedWords] = useState(new Set());
    const createFloatingText = useCallback(() => {
        // Filter the codingWords array to remove used words
        const availableWords = codingWords.filter(word => !usedWords.has(word));

        // If there are no words left, exit
        if (availableWords.length === 0) return;

        // Randomly select a word from availableWords
        const randomWord = availableWords[Math.floor(Math.random() * availableWords.length)];

        // Create the floating text element
        const textElement = document.createElement('div');
        textElement.className = 'floating-text';
        textElement.style.left = Math.random() * 100 + 'vw';
        textElement.style.animationDuration = Math.random() * 4 + 8 + 's';
        textElement.innerText = randomWord;

        // Append the element to the container
        document.getElementById('floating-text-container')?.appendChild(textElement);

        // Mark this word as used by adding it to the set
        setUsedWords(prevUsedWords => new Set(prevUsedWords).add(randomWord));

        // Remove the element after animation ends
        textElement.addEventListener('animationend', () => textElement.remove());
    }, [codingWords, usedWords]);

    useEffect(() => {
        const interval = setInterval(createFloatingText, 1500);
        return () => clearInterval(interval);
    }, [createFloatingText]);



    return (
        <div className="login-wrapper">
            <div id="floating-text-container"></div>
            <Outlet />
        </div>
    )
}
export default AuthLayout;