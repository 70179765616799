import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Auth.css";
import AuthService from "../../../services/AuthService";
import { jwtDecode } from "jwt-decode";
import { Button, ConfigProvider, Form, Input, notification } from "antd";
import LocalStorageService from "../../../services/LocalStorage";
import PhoneNumberInput from "../../../components/PhoneInput";
// import PhoneInput from "antd-phone-input";
function Register() {
  const [form] = Form.useForm(); // Ant Design form instance
  const [loading, setLoading] = useState(false); // Loading state for the submit button
  // const [error, setError] = useState(''); // Error state for displaying custom errors
  const navigate = useNavigate();
  const token = LocalStorageService.getItem("token");

  // const validator = (_, { valid }) => {
  //   // if (valid(true)) return Promise.resolve(); // strict validation
  //   if (valid()) return Promise.resolve(); // non-strict validation
  //   return Promise.reject("Invalid phone number");
  // };

  // Redirect if user is already logged in
  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token, navigate]);

  const handleSubmit = async (values) => {
    if (values.password !== values.confirmPassword) {
      // setError('Passwords do not match');
      return;
    }
    
    try {
      setLoading(true); // Start loading
      const response = await AuthService.register(values);

      if (response.data.token) {
        LocalStorageService.setItem("token", response.data.token);
        const userData = jwtDecode(response.data.token);
        LocalStorageService.setItem("user", JSON.stringify(userData));

        navigate("/verification");
      }
    } catch (err) {
      notification.error({
        message: "Registration Failed",
        description:
          err || "An error occurred during registration. Please try again.",
        duration: 3,
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            primaryColor: "Black",
          },
          Form: {
            itemMarginBottom: "8px",
          },
        },
      }}
    >
      <div className="main-container">
        <h1>Register</h1>
        <Form
          form={form}
          name="registerForm"
          layout="vertical"
          autoComplete="off"
          initialValues={{
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            confirmPassword: "",
          }}
          onFinish={handleSubmit} // Trigger on successful form submission
        >
          <Form.Item
            label="First Name"
            name="first_name"
            rules={[
              {
                required: true,
                message: "Please enter your first name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Last Name"
            name="last_name"
            rules={[
              {
                required: true,
                message: "Please enter your last name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter your email!",
              },
              {
                type: "email",
                message: "Please enter a valid email address!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone"
            name="contact_no"
            rules={[
              {
                required: true,
                message: "Phone number is required",
              },
              {
                // pattern: /^\+\d{1,3}(-|\s)?\d{1,4}(-|\s)?\d{1,4}(-|\s)?\d{1,9}$/,
                // message: "Phone number must include country code and valid digits",
              },
            ]}
          >
            <PhoneNumberInput
              value={form.getFieldValue("contact_no") || ""}
              onChange={(phone) => form.setFieldsValue({ contact_no: phone })}
            />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button
              className="mt-8"
              htmlType="submit"
              type="primary"
              block
              loading={loading} // Loading spinner on button
            >
              {loading ? "Registering..." : "Register"}
            </Button>
          </Form.Item>
        </Form>

        <p className="footer-text">
          Already have an account? <Link to="/login">Login here</Link>
        </p>
      </div>
    </ConfigProvider>
  );
}

export default Register;
