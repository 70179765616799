import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Upload, message, Row, Col } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const GeneralForm = ({ initialValues, onFinish, loading }) => {
  const [form] = Form.useForm();
  const [avatarFile, setAvatarFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
      // If the initial avatar URL is available, set it as the preview
      if (initialValues.avatar) {
        setPreviewUrl(initialValues.avatar);
      }
    }
  }, [initialValues, form]);

  const handleFileChange = ({ file }) => {
    const isImage = file.type.startsWith('image/');
    const isUnderLimit = file.size / 1024 / 1024 < 2; // 2MB limit

    if (!isImage) {
      message.error('You can only upload image files');
      return;
    }
    if (!isUnderLimit) {
      message.error('Image must be smaller than 2MB');
      return;
    }

    // Update preview and selected file
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);

    setAvatarFile(file);
    message.success('Avatar selected successfully');
  };

  return (
    <div style={{ display: 'flex', gap: '40px', alignItems: 'flex-start' }}>
      {/* Form Section */}
      <div style={{ flex: 1 }}>
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => onFinish({ ...values, avatar: avatarFile })}
          onFinishFailed={() => message.error('Please correct the errors before submitting')}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="First Name"
                name="first_name"
                rules={[{ required: true, message: 'Please enter your first name' }]}
              >
                <Input placeholder="Enter your first name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label="Last Name" name="last_name">
                <Input placeholder="Enter your last name" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ type: 'email', message: 'Please enter a valid email' }]}
          >
            <Input placeholder="Enter your email" disabled />
          </Form.Item>
          <Form.Item style={{ float: 'right' }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      </div>

      {/* Avatar Preview Section */}
      <div style={{ width: '200px', textAlign: 'center' }}>
        <h3 style={{ fontSize: '16px', marginBottom: '16px' }}>Avatar Preview</h3>
        <div
          style={{
            width: '120px',
            height: '120px',
            margin: '0 auto',
            borderRadius: '50%',
            backgroundColor: '#f0f0f0',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {previewUrl ? (
            <img
              src={previewUrl}
              alt="Avatar Preview"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          ) : (
            <span style={{ color: '#aaa' }}>No Avatar</span>
          )}
        </div>
        <Upload
          beforeUpload={() => false} // Prevent auto-upload
          onChange={handleFileChange}
          showUploadList={false} // Hide upload list
        >
          <Button icon={<UploadOutlined />} style={{ marginTop: '16px' }}>
            Edit Avatar
          </Button>
        </Upload>
      </div>
    </div>
  );
};

export default GeneralForm;
