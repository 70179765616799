import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Modal, Form, message } from 'antd';
import AuthService from '../../../services/AuthService';
import { PlusOutlined } from '@ant-design/icons';
import DashboardHeader from '../../../components/DashboardHeader';
import UserForm from './Form';
import { USER_COLUMNS } from '../../../utils/constants/Constant';
import { DeleteHelper } from '../../../utils/helpers/DeleteHelper';

const UserManagement = () => {
  const hasRun = useRef(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null); // Track user being edited
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState('');


  // Fetch users using AuthService
  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await AuthService.getUsers();
      const users = response.data || []; // Extract users array from the response
      setUsers(users);
    } catch (error) {
      message.error(error || 'Failed to load users');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!hasRun.current) {
      fetchUsers();
      hasRun.current = true; // Mark as run
    }
  }, []);

  // Handle Add or Update User
  const handleSaveUser = async (values) => {
    try {
      setLoading(true);
      if (editingUser) {

        // Update user logic
        await AuthService.updateUser(editingUser.email, values);
        message.success('User updated successfully');
      } else {
        // Add new user logic
        await AuthService.addUser({
          email: values.email,
          password: values.password,
          first_name: values.first_name,
          last_name: values.last_name
        });
        message.success('User added successfully');
      }
      setIsModalVisible(false);
      form.resetFields();
      fetchUsers(); // Refresh user list
    } catch (error) {
      message.error(error || 'Failed to save user');
    } finally {
      setLoading(false);
    }
  };

  // Handle Delete User using AuthService
  // const handleDeleteUser = async (id) => {
  //   try {
  //     setLoading(true);
  //     await AuthService.deleteUser(id);
  //     message.success('User deleted successfully');
  //     fetchUsers(); // Refresh user list
  //   } catch (error) {
  //     message.error(error || 'Failed to delete user');
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleDeleteUser = (id) => {
    DeleteHelper(id, AuthService.deleteUser, fetchUsers, setLoading);
  };
  const filteredUsers = users.filter((user) =>
    user?.full_name.toLowerCase().includes(searchText.toLowerCase()) ||
    user?.email.toLowerCase().includes(searchText.toLowerCase()) ||
    user?.role.toLowerCase().includes(searchText.toLowerCase())

  );
  // Open Modal for Add or Edit
  const openModal = (user = null) => {
    setEditingUser(user);
    if (user) {
      form.setFieldsValue({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        role: user.role,
      });
    }
    setIsModalVisible(true);
  };

  return (
    <div>
      <DashboardHeader
        title="User Management"
        searchPlaceholder="Search User"
        onSearchChange={(e) => setSearchText(e.target.value)}
        buttons={[
          <Button
            key="add"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => openModal()}
          >
            Add User
          </Button>,
        ]}
      />
      <Table
        dataSource={filteredUsers || []}
        columns={USER_COLUMNS(openModal, handleDeleteUser)}
        rowKey="_id"
        loading={loading}
        pagination={{ pageSize: 20 }}
        bordered
      />
      <Modal
        title={editingUser ? 'Edit User' : 'Add User'}
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          form.resetFields();
          setEditingUser(null);
        }}
        footer={null}
      >
        <UserForm ref={form} editingUser={editingUser} onFinish={handleSaveUser} />
      </Modal>
    </div>
  );
};

export default UserManagement;