import { message } from 'antd';

export const DeleteHelper = async (
  id,
  deleteServiceFunction,
  fetchDataCallback,
  setLoading,
  ...callbackParams // Accept additional parameters for the callback
) => {
  try {
    setLoading(true);
    await deleteServiceFunction(id); // Call the specific service function passed as an argument
    message.success('Item deleted successfully');
    if (fetchDataCallback) {
      fetchDataCallback(...callbackParams); // Pass the additional parameters to the callback
    }
    return { success: true };

  } catch (error) {
    message.error(error?.message || 'Failed to delete item');
  } finally {
    setLoading(false);
  }
};
