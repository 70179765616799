import country from "../data/index.json";
import flat from "../data/flat.json";

export const countries = country; // Export countries for use in PhoneNumberInput

export function countrySearchName(c, search) {
  return c.filter(
    (x) =>
      x.name.toLowerCase().includes(search.toLowerCase()) ||
      x.currency_name.toLowerCase().includes(search.toLowerCase()) ||
      x.region.toLowerCase().includes(search.toLowerCase()) ||
      x.subregion.toLowerCase().includes(search.toLowerCase())
  );
}

export function extractData(c, keys = []) {
  let output = c;
  if (keys.length > 0) {
    output = c.map((obj) => {
      return keys.reduce((acc, curr) => {
        if (curr.includes(".")) {
          const [parentKey, childKey] = curr.split(".");
          acc[parentKey] = acc[parentKey] || {};
          acc[parentKey][childKey] = obj[parentKey][childKey];
        } else {
          acc[curr] = obj[curr];
        }
        return acc;
      }, {});
    });
  }
  return output;
}

export function getAllCountries(options = {}) {
  const {
    sortBy = [],
    filter = null,
    limit = country.length,
    keys = [],
  } = options;

  let countries = [...country];

  if (sortBy && sortBy.length > 0) {
    const validCode = [];
    const topCountries = [];
    sortBy.forEach((c) => {
      if (flat[c]) {
        topCountries.push(flat[c]);
        validCode.push(c);
      }
    });
    const allFilterByCode = countries.filter(
      (x) => !validCode.includes(x.iso2)
    );
    countries = [...topCountries, ...allFilterByCode];
  }

  if (filter && typeof filter === "object" && filter.length > 0) {
    countries = countries.filter((x) => filter.includes(x.iso2));
  } else if (filter && typeof filter === "string" && filter.length > 0) {
    countries = countrySearchName(countries, filter);
  }

  let records = countries;
  if (limit > 0) {
    records = records.slice(0, limit);
  }

  records = extractData(records, keys);

  return { rows: records, count: records.length };
}
