import React, { useState } from 'react';
import { message } from 'antd';
import BookingForm from './Form';
import BookingService from '../../services/BookingService';

const Booking = () => {
  const [loading, setLoading] = useState(false);
  const [booking, setBooking] = useState(null);



  const handleFinish = async (values) => {
    try {
      setLoading(true);

      const newBooking = await BookingService.createBooking(values);
      localStorage.setItem('booking', JSON.stringify(newBooking));
      setBooking(newBooking);
      message.success('Booking created successfully');
    } catch (error) {
      console.error(error);
      message.error('Failed to create booking');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '60%', margin: '0 auto' }}>
      <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '4px' }}>Create Booking</h2>
      <p style={{ fontSize: '14px', marginBottom: '24px' }}>
        Fill in the details to create a new booking.
      </p>
      <BookingForm initialValues={booking} onFinish={handleFinish} loading={loading} />
    </div>
  );
};

export default Booking;
