import React, { useEffect, useState, useContext } from 'react';
import { message, Radio } from 'antd';
import GeneralForm from './Form';
import AuthService from '../../../services/AuthService';
import { ThemeContext } from '../../../contexts/ThemeContext';
import LocalStorageService from '../../../services/LocalStorage';


const General = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const { appearance, updateAppearance } = useContext(ThemeContext);

  useEffect(() => {
    const user = LocalStorageService.getItem('user');
    if (user) {
      setUser(JSON.parse(user)?.data);
    } else {
      message.error('Invalid or expired token. Please log in again.');
    }


  }, []);

  const handleFinish = async (values) => {
    const { avatar, ...userData } = values;

    try {
      setLoading(true);
      const formData = new FormData();

      if (avatar) {
        formData.append('file', avatar);
      }

      // Append user data to FormData
      Object.entries(userData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const updatedUser = await AuthService.updateUser(user.email, formData);
      LocalStorageService.setItem("user", JSON.stringify(updatedUser))
      setUser(updatedUser?.data);
      message.success('User info updated successfully');
    } catch (error) {
      console.error(error);
      message.error('Failed to update user info');
    } finally {
      setLoading(false);
    }
  };


  const handleAppearanceChange = async (e) => {
    const newAppearance = e.target.value;
    try {
      if (!user?.email) {
        message.error("User email is missing.");
        return;
      }
      await AuthService.updateAppearancePreference(user.email, { appearance_preference: newAppearance });
      updateAppearance(newAppearance);
      message.success(`Appearance preference updated to ${newAppearance}`);
    } catch (error) {
      message.error(error || "Failed to update appearance preference.");
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '40%', margin: "0 auto" }}>
      <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '4px' }}>General</h2>
      <p style={{ fontSize: '14px', color: '#888', marginBottom: '24px' }}>
        Update your personal information and preferences.
      </p>

      {/* Appearance Preference Section */}
      <div style={{ marginBottom: '24px', display: "flex", gap: "10px" }}>
        <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '8px' }}>
          Appearance Preference :
        </h3>

        <Radio.Group onChange={handleAppearanceChange} value={appearance}>
          <Radio value="light">Light</Radio>
          <Radio value="dark">Dark</Radio>
          <Radio value="system">System</Radio>
        </Radio.Group>
      </div>

      <GeneralForm initialValues={user} onFinish={handleFinish} loading={loading} />
    </div>
  );
};

export default General;
