import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Typography, Spin, message, Row, Col, Affix, Modal } from "antd";
import dayjs from "dayjs";
import AdsService from "../../../services/AdService";
import "./Detail.css";
import ImageGallery from "./ImageGallery";
import LeafletMap from "./Map";
import Itinerary from "./Itinerary";

import BookingForm from "../../booking/Form";
import BookingCard from "./BookingCard";
import LocalStorageService from "../../../services/LocalStorage";
import BookingsService from "../../../services/BookingService";
import InformationDetail from "./InformationDetail";

const { Text, Paragraph, Title } = Typography;

const Detail = () => {
  const windowWidth = window.innerWidth;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const adId = queryParams.get("search");

  const [adDetails, setAdDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [container, setContainer] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isBooked, setIsBooked] = useState(false);

  const positions = [
    [34.0479, 71.5822],
    [33.6844, 73.0479],
    [31.5204, 74.3587],
  ];

  useEffect(() => {
    if (adId) {
      fetchAdDetails(adId);
    } else {
      message.error("Ad ID not found in URL.");
      setLoading(false);
    }
  }, [adId]);

  const fetchAdDetails = async (adId) => {
    try {
      const { data } = await AdsService.getAdById(adId);
      setAdDetails(data);
      setIsBooked(data.booked);
    } catch (error) {
      message.error("Failed to load adventure details. Please try again.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const formatDateDetails = (date) => ({
    day: dayjs(date).format("ddd"),
    date: dayjs(date).format("D"),
    monthYear: dayjs(date).format("MMM, YYYY"),
    time: dayjs(date).format("h:mm A"),
  });
  const handleSaveBooking = async (bookingValues) => {
    try {
      // Prepare the booking data
      const bookingData = {
        ad: { _id: bookingValues.ad, title: adDetails.title },
        user:
          JSON.parse(LocalStorageService.getItem("user") || "{}")?.data?.id ||
          null,
        user_email: bookingValues.user,
        contact_no: bookingValues.contact_no || "",
        full_name: bookingValues.full_name || "",
        organization: adDetails.organization._id,
        booking_date: new Date(),
        start_date: bookingValues.start_date,
        end_date: bookingValues.end_date,
        participants: bookingValues.participants,
        total_price: bookingValues.total_price,
        special_requests: bookingValues.special_requests,
      };

      // Call the API to save the booking
      await BookingsService.createBooking(bookingData);
      setIsBooked(true);
      // Show success message
      message.success("Booking saved successfully!");
      handleModalClose();
    } catch (error) {
      // Show error message
      message.error("Failed to save booking. Please try again.");
      console.error("Booking Error:", error);
    }
  };

  const showModal = () => setIsModalVisible(true);
  const handleModalClose = () => setIsModalVisible(false);

  if (loading) {
    return (
      <div className="loading-container">
        <Spin tip="Loading adventure details..." size="large" />
      </div>
    );
  }

  if (!adDetails) {
    return (
      <div className="loading-container">
        <Text type="danger">Ad details not found.</Text>
      </div>
    );
  }

  const startDetails = formatDateDetails(adDetails?.start_date);
  const endDetails = formatDateDetails(adDetails?.end_date);

  return (
    <div
      className="adventure-detail-container"
      style={{ overflowY: "auto" }}
      ref={setContainer}
    >
      <div className="adventure-image-container">
        <ImageGallery images={adDetails?.images} title={adDetails?.title} />
      </div>

      {windowWidth <= 990 && (
        <BookingCard
          width={windowWidth}
          price={adDetails?.price}
          duration={adDetails?.duration}
          startDetails={startDetails}
          endDetails={endDetails}
          adId={adId} // Pass the adId to BookingCard
          favorite={adDetails.favorite}
          setBooked={setIsBooked}
          booked={isBooked}
          is_cancelled={adDetails.is_cancelled}
          status={adDetails.status}
          booking_id={adDetails.booking_id}
          showModal={showModal}
        />
      )}
      <Row
        style={{ padding: "1rem" }}
        gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
      >
        <Col xs={24} md={24} lg={16}>
          <div className="adventure-details">
            <div id="info">
              <Title className="adventure-title">
                {adDetails?.title}  <span style={{ color: 'var(--primary-color)', fontSize: "18px" }}>({adDetails?.organization?.name})</span>
              </Title>

              <Paragraph className="adventure-description">
                {adDetails?.description}
              </Paragraph>
            </div>
            <div id="itinerary">
              <Itinerary itinerary={adDetails?.itinerary} />
            </div>
            <InformationDetail adDetails={adDetails} />
            <div id="map-container">
              <Title level={3} className="map">
                Map
              </Title>
              <div id="map" style={{ height: "30rem", width: "100%" }}>
                <LeafletMap locations={positions} />
              </div>
            </div>
          </div>
        </Col>

        {windowWidth > 990 && (
          <Col xs={24} md={24} lg={8} style={{ marginTop: "34px" }}>
            <Affix target={() => container} offsetTop={150}>
              <BookingCard
                price={adDetails?.price}
                duration={adDetails?.duration}
                startDetails={startDetails}
                endDetails={endDetails}
                favorite={adDetails.favorite}
                booked={isBooked}
                status={adDetails.status}
                booking_id={adDetails.booking_id}
                is_cancelled={adDetails.is_cancelled}
                setBooked={setIsBooked}
                adId={adId} // Pass the adId to BookingCard
                showModal={showModal}
              />
            </Affix>
          </Col>
        )}
      </Row>

      <Modal
        title="Book Your Adventure"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <BookingForm
          initialValues={{
            ad: adDetails,
            participants: 1,
            total_price: adDetails?.price,
            user:
              JSON.parse(LocalStorageService.getItem("user") || "{}")?.data
                ?.email || "Dummy",
            organization: adDetails?.organization,
            booking_dates:
              adDetails?.start_date && adDetails?.end_date
                ? [adDetails?.start_date, adDetails?.end_date]
                : null,
            special_requests: "",
          }}
          onFinish={(values) => handleSaveBooking(values)}
        />
      </Modal>
    </div>
  );
};

export default Detail;
