import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Modal, Form, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import BookingForm from './Form'; // Import the BookingForm component
import BookingService from '../../../services/BookingService'; // Custom service to handle API calls
import DashboardHeader from '../../../components/DashboardHeader';
import { BOOKING_COLUMNS } from "../../../utils/constants/Constant";

const BookingManagement = () => {
  const hasRun = useRef(false);
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingId, setLoadingId] = useState(null); // Track which button is loading

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingBooking, setEditingBooking] = useState(null);
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState('');

  // Fetch bookings using BookingService
  const fetchBookings = async () => {
    try {
      setLoading(true);
      const response = await BookingService.getBookings();
      setBookings(response.data || []); // Set fetched bookings
    } catch (error) {
      message.error('Failed to load bookings');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!hasRun.current) {
      fetchBookings();
      hasRun.current = true;
    }
  }, []);

  // Handle Add or Update Booking
  const handleSaveBooking = async (values) => {
    try {
      console.log("values", values);

      // values["contact_no"] = `${values.contact_no.countryCode}${values.contact_no.areaCode}${values.contact_no.phoneNumber}`
      setLoading(true);
      if (editingBooking) {
        // Update booking logic
        await BookingService.updateBooking(editingBooking._id, values);
        message.success('Booking updated successfully');
      } else {
        // Add new booking logic
        await BookingService.createBooking(values);
        message.success('Booking added successfully');
      }
      setIsModalVisible(false);
      form.resetFields();
      fetchBookings(); // Refresh booking list
    } catch (error) {
      message.error('Failed to save booking');
    } finally {
      setLoading(false);
    }
  };

  // Handle Delete Booking
  const handleDeleteBooking = async (id) => {
    try {
      setLoading(true);
      await BookingService.deleteBooking(id);
      message.success('Booking deleted successfully');
      fetchBookings(); // Refresh booking list
    } catch (error) {
      message.error('Failed to delete booking');
    } finally {
      setLoading(false);
    }
  };

  const openModal = (booking = null) => {
    setEditingBooking(booking);
    if (!booking) {
      form.resetFields();
    }
    setIsModalVisible(true);
  };



  const filteredBookings = bookings.filter((booking) =>
    Object.values(booking).some((value) =>
      String(value).toLowerCase().includes(searchText.toLowerCase())
    )
  );

  return (
    <div>
      <DashboardHeader
        title="Booking Management"
        searchPlaceholder="Search Booking"
        onSearchChange={(e) => setSearchText(e.target.value)}
        buttons={[
          <Button
            key="add"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => openModal()}
          >
            Add Booking
          </Button>,
        ]}
      />
      <Table
        dataSource={filteredBookings || []}
        columns={BOOKING_COLUMNS(openModal, handleDeleteBooking, fetchBookings, loadingId, setLoadingId, BookingService)}
        rowKey="_id"
        loading={loading}
        pagination={{ pageSize: 20 }}
        bordered
      />
      <Modal
        title={editingBooking ? 'Edit Booking' : 'Add Booking'}
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          form.resetFields();
          setEditingBooking(null);
        }}
        footer={null}
      >
        <BookingForm
          initialValues={editingBooking}
          onFinish={handleSaveBooking}
          loading={loading}
        />
      </Modal>
    </div>
  );
};

export default BookingManagement;
