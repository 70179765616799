import React, { useCallback, useState } from 'react';
import { UserOutlined, LockOutlined, PoweroffOutlined } from '@ant-design/icons';
import { Button, Layout, Menu, theme } from 'antd';
import { Link, useNavigate, Outlet, useLocation } from 'react-router-dom';
import './MyAccount.css';
import LocalStorageService from '../../services/LocalStorage';

const { Content, Footer, Sider } = Layout;

function getItem(label, key, icon) {
    return {
        key,
        icon,
        label,
    };
}

// Menu items for My Account
const items = [
    getItem('General', '/my-account/general', <UserOutlined />),
    getItem('Security', '/my-account/security', <LockOutlined />)
];

const MyAccount = () => {
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = useCallback(() => {
        LocalStorageService.logout(navigate)
    }, [navigate]);

    const onMenuClick = ({ key }) => {
        navigate(key); // Navigate to the selected menu item's route
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
                theme="dark"
                style={{ position: 'relative' }}
            >
                {/* Logo and Text */}
                <div className="navbar-logo">
                    <Link to="/" className="navbar-logo-link">
                        <img
                            alt="TourismPk Logo"
                            src={collapsed ? "/images/LOGO-YELLOW.png" : "/images/NEW-WHITE-ALL-YELLOW.png"}
                            style={{
                                width: collapsed ? "50px" : "150px",
                                height: "auto",
                                margin: "16px auto",
                                display: "block",
                            }}
                        />
                    </Link>
                </div>

                {/* Menu */}
                <Menu
                    theme="dark"
                    mode="inline"
                    items={items}
                    onClick={(e) => onMenuClick(e)}
                    selectedKeys={[location.pathname]}
                />

                {/* Logout Button */}
                <Button
                    type="primary"
                    danger
                    icon={<PoweroffOutlined />}
                    onClick={handleLogout}
                    style={{
                        position: 'absolute',
                        bottom: '4rem',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '90%',
                    }}
                >
                    {!collapsed && 'Logout'}
                </Button>
            </Sider>

            {/* Main Content */}
            <Layout>
                <Content
                    style={{
                        margin: '24px 16px 0',
                        padding: 24,
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG,
                        height: 'calc(100vh - 100px)',
                        overflow: 'hidden',
                    }}
                >
                    <div className="outlet" style={{ overflow: 'auto', height: '100%' }}>
                        <Outlet />
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>TourismPk ©2024</Footer>
            </Layout>
        </Layout>
    );
};

export default MyAccount;
