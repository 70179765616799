import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Modal, Form, message } from 'antd';
import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import CitiesService from '../../../services/CitiesService'; // Replace with your CitiesService path
import * as Papa from 'papaparse'; // Install papaparse for CSV parsing
import DashboardHeader from '../../../components/DashboardHeader';
import CityForm from './Form';
import { DeleteHelper } from '../../../utils/helpers/DeleteHelper';
import { CITY_COLUMNS } from '../../../utils/constants/Constant';


const CitiesManagement = () => {
  const hasRun = useRef(false);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingCity, setEditingCity] = useState(null);
  const [bulkCities, setBulkCities] = useState([]); // Store imported data
  const [searchText, setSearchText] = useState('');
  const [form] = Form.useForm();
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCities, setTotalCities] = useState(0);

  // Fetch cities using CitiesService
  const fetchCities = async (page = 1, pageSize = 15) => {
    try {
      setLoading(true);
      const { data, total } = await CitiesService.getCities(page, pageSize);
      setCities(data || []);
      setTotalCities(total || 0);
    } catch (error) {
      message.error(error || 'Failed to load cities');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!hasRun.current) {
      fetchCities(currentPage, pageSize);
      hasRun.current = true; // Mark as run
    }
  }, [currentPage, pageSize]);

  // Handle Add or Update City
  const handleSaveCity = async (values) => {
    try {
      setLoading(true);
      if (editingCity) {
        await CitiesService.updateCity(editingCity._id, values);
        message.success('City updated successfully');
      } else {
        await CitiesService.addCity(values);
        message.success('City added successfully');
      }
      setIsModalVisible(false);
      form.resetFields();
      fetchCities();
    } catch (error) {
      message.error(error || 'Failed to save city');
    } finally {
      setLoading(false);
    }
  };
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
    fetchCities(pagination.current, pagination.pageSize);

  };
  const handleDeleteCity = async (id) => {
    DeleteHelper(id, CitiesService.deleteCity, fetchCities, setLoading, currentPage, pageSize);
  };

  const handleToggleStatus = async (city) => {
    try {
      setLoading(true);
      await CitiesService.updateCity(city._id, { is_active: !city.is_active });
      message.success(`City status updated to ${!city.is_active ? 'Inactive' : 'Active'}`);
      fetchCities();
    } catch (error) {
      message.error('Failed to update city status');
    } finally {
      setLoading(false);
    }
  };
  const filteredCities = cities.filter((city) =>
    city?.name.toLowerCase().includes(searchText.toLowerCase()) ||
    city?.province.toLowerCase().includes(searchText.toLowerCase()) ||
    city?.division.toLowerCase().includes(searchText.toLowerCase()) ||
    city?.district.toLowerCase().includes(searchText.toLowerCase())
  );
  // Open Modal for Add or Edit
  const openModal = (city = null) => {
    setEditingCity(city);
    if (city) {
      form.setFieldsValue({
        name: city.name,
        country: city.country,
        province: city.province,
        division: city.division,
        district: city.district,
        is_active: city.is_active,
      });
    }
    setIsModalVisible(true);
  };

  // Export cities data to CSV
  const handleExport = () => {
    const csv = Papa.unparse(cities);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'cities.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Import cities data from CSV with enhanced parsing
  const handleImport = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      Papa.parse(text, {
        header: true,
        skipEmptyLines: true, // Skip empty rows
        dynamicTyping: true,  // Automatically typecast numbers and booleans
        complete: function (results) {
          if (results.errors.length > 0) {
            console.error("Errors during parsing:", results.errors);
            message.error("Failed to parse CSV due to formatting issues.");
          } else {
            setBulkCities(results.data); // Save parsed data
            message.success("Cities imported successfully.");
          }
        },
      });
    };
    reader.readAsText(file);
  };

  // Handle Bulk Create API Call
  const handleBulkCreate = async () => {
    if (!bulkCities.length) {
      message.error('No cities to create. Please import a file first.');
      return;
    }
    try {
      setLoading(true);

      const results = await Promise.all(
        bulkCities.map(async (city) => {
          try {
            await CitiesService.addCity({
              name: city.name,
              country: city.country,
              province: city.province,
              division: city.division,
              district: city.district,
              is_active: city.is_active === 'true',
            });
            return { city: city.name, success: true }; // Successful creation
          } catch (error) {
            if (error.response?.data?.code === 11000) {
              // Handle duplicate key error
              console.warn(`Duplicate city: ${city.name}`);
              return { city: city.name, success: false, error: 'Duplicate key' };
            } else {
              // Log other errors
              console.error(`Error creating city: ${city.name}`, error);
              return { city: city.name, success: false, error: error.message };
            }
          }
        })
      );

      // Analyze the results
      const successful = results.filter((result) => result.success);
      const failed = results.filter((result) => !result.success);

      if (successful.length > 0) {
        message.success(`${successful.length} cities created successfully.`);
      }
      if (failed.length > 0) {
        message.warning(
          `${failed.length} cities failed to create due to errors. Check console for details.`
        );
      }

      setBulkCities([]); // Clear the imported data
      fetchCities(); // Refresh city list
    } catch (error) {
      message.error('Failed to create bulk cities due to an unexpected error.');
    } finally {
      setLoading(false);
    }
  };


  return (
    <div>
      <DashboardHeader
        title="Cities Management"
        searchPlaceholder="Search City"
        onSearchChange={(e) => setSearchText(e.target.value)}
        buttons={[
          <Button key="add" type="primary" icon={<PlusOutlined />} onClick={() => openModal()}>
            Add City
          </Button>,
          <Button key="export" type="default" icon={<DownloadOutlined />} onClick={handleExport}>
            Export
          </Button>,
          bulkCities.length > 0 && (
            <Button key="bulkCreate" type="primary" onClick={handleBulkCreate}>
              Create Bulk Cities
            </Button>
          ),
        ]}
        uploadProps={{
          accept: '.csv',
          beforeUpload: (file) => {
            handleImport(file);
            return false;
          },
        }}
      />
      <Table
        dataSource={filteredCities || []}
        columns={CITY_COLUMNS(openModal, handleDeleteCity, handleToggleStatus)}
        rowKey="_id"
        loading={loading}
        pagination={{
          current: currentPage,
          total: totalCities,
          pageSize: pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '15', '20', '30', '50'],
        }}
        onChange={handleTableChange}
        bordered
      />
      <Modal
        title={editingCity ? 'Edit City' : 'Add City'}
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          form.resetFields();
          setEditingCity(null);
        }}
        footer={null}
      >
        <CityForm ref={form} editingCity={editingCity} onFinish={handleSaveCity} />
      </Modal>
    </div>
  );
};

export default CitiesManagement;
