import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Modal, Form, message } from 'antd';
import OrganizationService from '../../../services/OrganizationService';
import AuthService from '../../../services/AuthService'; // Import the AuthService
import { PlusOutlined } from '@ant-design/icons';
import DashboardHeader from '../../../components/DashboardHeader';
import OrganizationForm from './Form';
import { DeleteHelper } from '../../../utils/helpers/DeleteHelper';
import { ORGANIZATION_COLUMNS } from '../../../utils/constants/Constant';


const Organization = () => {
  const hasRun = useRef(false);
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingOrganization, setEditingOrganization] = useState(null);
  const [users, setUsers] = useState([]); // State to hold user options
  const [searchText, setSearchText] = useState('');

  const [form] = Form.useForm();

  // Fetch organizations
  const fetchOrganizations = async () => {
    try {
      setLoading(true);
      const response = await OrganizationService.getOrganizations();
      setOrganizations(response.data || []);
    } catch (error) {
      message.error(error || 'Failed to load organizations');
    } finally {
      setLoading(false);
    }
  };

  // Fetch users for the user_id dropdown
  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await AuthService.getUsers(); // Fetch users from AuthService
      setUsers(response.data || []);
    } catch (error) {
      message.error(error || 'Failed to load users');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!hasRun.current) {
      fetchOrganizations();
      fetchUsers();
      hasRun.current = true;
    }
  }, []);

  const handleSaveOrganization = async (values) => {
    try {
      setLoading(true);
      if (editingOrganization) {
        await OrganizationService.updateOrganization(editingOrganization._id, values);
        message.success('Organization updated successfully');
      } else {
        await OrganizationService.addOrganization(values);
        message.success('Organization added successfully');
      }
      setIsModalVisible(false);
      form.resetFields();
      fetchOrganizations();
    } catch (error) {
      message.error(error || 'Failed to save organization');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteOrganization = async (id) => {
    DeleteHelper(id, OrganizationService.deleteOrganization, fetchOrganizations, setLoading);
  };

  const filteredOrganization = organizations.filter((organization) =>
    organization?.name.toLowerCase().includes(searchText.toLowerCase()) ||
    organization?.contact_email.toLowerCase().includes(searchText.toLowerCase()) ||
    organization?.contact_phone.toLowerCase().includes(searchText.toLowerCase()) ||
    organization?.website.toLowerCase().includes(searchText.toLowerCase())
  );

  const openModal = (organization = null) => {

    setEditingOrganization(organization);
    if (organization) {

      form.setFieldsValue({
        name: organization.name,
        description: organization.description,
        contact_email: organization.contact_email,
        contact_phone: organization.contact_phone,
        user_id: organization.user_id,
        website: organization.website,
        address: organization.address,
      });
    } else {
      form.resetFields();
    }
    setIsModalVisible(true);
  };

  return (
    <div>
      <DashboardHeader
        title="Organizations"
        searchPlaceholder="Search Organizations"
        onSearchChange={(e) => setSearchText(e.target.value)}
        buttons={[
          <Button
            key="add"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => openModal()}
          >
            Add Organization
          </Button>,
        ]}
      />
      <Table
        dataSource={filteredOrganization || []}
        columns={ORGANIZATION_COLUMNS(openModal, handleDeleteOrganization)}
        rowKey="_id"
        loading={loading}
        pagination={{ pageSize: 10 }}
        bordered
      />

      <Modal
        title={editingOrganization ? 'Edit Organization' : 'Add Organization'}
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          form.resetFields();
          setEditingOrganization(null);
        }}
        footer={null}
      >
        <OrganizationForm
          ref={form}
          editingOrganization={editingOrganization}
          onFinish={handleSaveOrganization}
          users={users}
        />
      </Modal>
    </div>
  );
};

export default Organization;
