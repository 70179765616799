import React from "react";
import { useLocation, Outlet } from "react-router-dom";
import Navbar from "./Navbar/Navbar"; // Import the Navbar component

function Layout() {
  const location = useLocation();

  // Adjust the condition to hide Navbar on specific paths
  const hideNavbar = ["/login", "/register", "/verification", "/reset-password"].includes(location.pathname);

  return (
    <div>
      {!hideNavbar && <Navbar />} {/* Show the Navbar unless on specific paths */}
      <main>
        <Outlet /> {/* Render child routes here */}
      </main>
    </div>
  );
}

export default Layout;
