import React, { useState, useEffect, useRef } from "react";
import { Table, Button, Modal, Form, message } from "antd";
import AdsService from "../../../services/AdService";
import CitiesService from "../../../services/CitiesService";
import OrganizationsService from "../../../services/OrganizationService";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs"; // Import Day.js for date handling
import DashboardHeader from "../../../components/DashboardHeader";
import AdForm from "./Form";
import { DeleteHelper } from "../../../utils/helpers/DeleteHelper";
import { AD_COLUMNS } from "../../../utils/constants/Constant";

const AdsManagement = () => {
  const hasRun = useRef(false);
  const [ads, setAds] = useState([]);
  const [cities, setCities] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFullPage, setIsFullPage] = useState(false); // New state to manage full-page mode
  const [editingAd, setEditingAd] = useState(null);
  const [searchText, setSearchText] = useState("");
  const colors = [
    "blue",
    "green",
    "orange",
    "red",
    "purple",
    "volcano",
    "cyan",
  ];

  const [form] = Form.useForm();

  // Fetch ads
  const fetchAds = async () => {
    try {
      setLoading(true);
      const selectFields = {
        ads: "title description duration start_date end_date price",
        organization: "name",
        cities_covered: "name",
      };
      const response = await AdsService.getAds(selectFields);
      setAds(response.data || []);
    } catch (error) {
      message.error(error || "Failed to load ads");
    } finally {
      setLoading(false);
    }
  };

  // Fetch cities
  const fetchCities = async () => {
    try {
      const response = await CitiesService.searchAllCities();
      setCities(response.data || []);
    } catch (error) {
      message.error(error || "Failed to load cities");
      setCities([]);
    }
  };

  // Fetch organizations
  const fetchOrganizations = async () => {
    try {
      setLoading(true);
      const response = await OrganizationsService.getOrganizations();
      setOrganizations(response.data || []);
    } catch (error) {
      message.error(error || "Failed to load organizations");
      setOrganizations([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!hasRun.current) {
      fetchAds();
      fetchCities();
      fetchOrganizations();
      hasRun.current = true;
    }
  }, []);

  const handleSaveAd = async (values) => {
    try {
      setPostLoading(true);

      // Format the values
      const formattedValues = {
        ...values,
        price_includes: values.price_includes
          ? values.price_includes.split(",").map((item) => item.trim())
          : [],
        price_excludes: values.price_excludes
          ? values.price_excludes.split(",").map((item) => item.trim())
          : [],
        additional_services: values.additional_services
          ? values.additional_services.split(",").map((item) => item.trim())
          : [],
        hotels: JSON.stringify(values.hotels || []),
        start_date: dayjs(values.start_date).format("YYYY-MM-DD"),
        end_date: dayjs(values.end_date).format("YYYY-MM-DD"),
        is_active: values.is_active || false,
        is_featured: values.is_featured || false,
        cities_covered:
          typeof values.cities_covered === "string"
            ? values.cities_covered.split(",")
            : values.cities_covered || [],
        itinerary: JSON.stringify(values.itinerary || []),
        maximum_participants: isNaN(Number(values.maximum_participants))
          ? null
          : Number(values.maximum_participants),
        meeting_point:
          values.meeting_point === "undefined" ? null : values.meeting_point,
        contact_information:
          values.contact_information === "undefined"
            ? null
            : values.contact_information,
        highlights:
          values.highlights === "undefined" ? null : values.highlights,
        tags: values.tags === "undefined" ? null : values.tags,
        terms_and_conditions:
          values.terms_and_conditions === "undefined"
            ? null
            : values.terms_and_conditions,
      };

      // Create FormData
      const formData = new FormData();

      // Append images
      if (formattedValues.images && Array.isArray(formattedValues.images)) {
        formattedValues.images.forEach((image, index) => {
          const file = image.originFileObj || image.file; // Ensure it's the actual file
          if (file) {
            formData.append("files", file);
          }
        });
      }

      // Append other fields
      Object.entries(formattedValues).forEach(([key, value]) => {
        if (key === "images") return; // Skip images since they are already appended
        if (Array.isArray(value) || typeof value === "object") {
          formData.append(key, JSON.stringify(value)); // Stringify arrays/objects
        } else if (value !== null && value !== undefined) {
          formData.append(key, value); // Append other fields
        }
      });

      // Send the request
      if (editingAd) {
        if (
          formattedValues.images?.length &&
          formattedValues?.images?.length > 0
        ) {
          formattedValues?.images.forEach((item, index) => {
            if (item?.url) formData.append("images[]", item?.url);
          });
        }
        await AdsService.updateAd(editingAd._id, formData);
        message.success("Ad updated successfully");
      } else {
        await AdsService.createAd(formData);
        message.success("Ad added successfully");
      }

      setIsModalVisible(false);
      form.resetFields();
      closeFullPage();
      fetchAds();
    } catch (error) {
      console.error("Error saving ad:", error);
      message.error("Failed to save ad");
    } finally {
      setPostLoading(false);
    }
  };

  const handleDeleteAd = async (id) => {
    DeleteHelper(id, AdsService.deleteAd, fetchAds, setLoading);
  };

  const filteredAds = ads.filter(
    (ad) =>
      ad?.title.toLowerCase().includes(searchText.toLowerCase()) ||
      ad?.description.toLowerCase().includes(searchText.toLowerCase())
  );

  const fetchAdDetail = async (id) => {
    try {
      setLoading(true);
      const response = await AdsService.getAdById(id);
      openModal(response.data || []);
    } catch (error) {
      message.error(error || "Failed to load ads");
    } finally {
      setLoading(false);
    }
  };

  const openModal = (ad = null) => {
    setEditingAd(ad);
    if (ad) {
      form.setFieldsValue({
        title: ad.title,
        description: ad.description,
        price: ad.price,
        duration: ad.duration,
        start_date: ad.start_date ? dayjs(ad.start_date) : null,
        end_date: ad.end_date ? dayjs(ad.end_date) : null,
        cities_covered: ad.cities_covered?.map((city) => city._id) || [],
        organization: ad.organization?._id || null,
        itinerary: ad.itinerary?.map(({ _id, ...rest }) => ({
          ...rest,
          day: rest.day || "",
          title: rest.title || "",
          activities: rest.activities || "",
        })) || [],
        
        departure: ad.departure || "",
        return_location: ad.return_location || "",
        price_includes: ad.price_includes?.join(", ") || "",
        price_excludes: ad.price_excludes?.join(", ") || "",
        additional_services: ad.additional_services?.join(", ") || "",
        transportation_type: ad.transportation_type || "Bus",
        hotels: ad.hotels?.map(({ _id, ...rest }) => rest) || [],
        contact_information: {
          phone: ad.contact_information?.phone || "",
          email: ad.contact_information?.email || "",
        },
        meeting_point: ad.meeting_point || "",
        maximum_participants: ad.maximum_participants || 0,
        remaining_slots: ad.remaining_slots || 0,
        highlights: ad.highlights || "",
        terms_and_conditions: ad.terms_and_conditions || "",
        tags: ad.tags || [],
        is_featured: ad.is_featured || false,
        is_active: ad.is_active || false,
      });
    } else {
      form.resetFields();
    }
    setIsModalVisible(true);
  };

  const openFullPage = () => {
    setEditingAd(null); // Reset editing ad
    form.resetFields();
    setIsFullPage(true); // Enable full-page mode
  };

  const closeFullPage = () => {
    setIsFullPage(false); // Disable full-page mode
  };

  return (
    <div>
      <DashboardHeader
        title="Ads Management"
        searchPlaceholder="Search Ads"
        onSearchChange={(e) => setSearchText(e.target.value)}
        buttons={[
          <Button
            key="add"
            type="primary"
            icon={<PlusOutlined />}
            onClick={openFullPage}
          >
            Add Ad
          </Button>,
        ]}
      />
      {!isFullPage ? (
        <>
          <Table
            dataSource={filteredAds || []}
            columns={AD_COLUMNS(fetchAdDetail, handleDeleteAd, colors)}
            rowKey="_id"
            loading={loading}
            pagination={{ pageSize: 10 }}
            bordered
          />
          <Modal
            title={editingAd ? "Edit Ad" : "Add Ad"}
            open={isModalVisible}
            onCancel={() => {
              setIsModalVisible(false);
              form.resetFields();
              setEditingAd(null);
            }}
            footer={null}
          >
            <AdForm
              ref={form}
              editingAd={editingAd}
              onFinish={handleSaveAd}
              cities={cities}
              organizations={organizations}
            />
          </Modal>
        </>
      ) : (
        <div style={{ padding: "20px", borderRadius: "8px" }}>
          <Button
            type="default"
            onClick={closeFullPage}
            style={{ marginBottom: "20px" }}
          >
            Back to List
          </Button>
          <AdForm
            ref={form}
            editingAd={editingAd}
            onFinish={handleSaveAd}
            cities={cities}
            organizations={organizations}
            loading={postLoading}
          />
        </div>
      )}
    </div>
  );
};

export default AdsManagement;
