import React, { useState, useEffect, useCallback } from "react";
import { Menu, Drawer, Button } from "antd";
import { CalendarOutlined, HeartOutlined, MenuOutlined } from "@ant-design/icons";
import "./Navbar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserIcon from "../UserIcon/UserIcon"; // Import the UserIcon component
import LocalStorageService from "../../services/LocalStorage";

const Navbar = () => {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  const token = LocalStorageService.getItem("token"); // Get the token from local storage
  const location = useLocation();
  const currentKey =
    location.pathname === "/" ? "home" : location.pathname.substring(1);

  const handleLogout = useCallback(() => {
    LocalStorageService.logout(navigate);
  }, [navigate]);

  const menuItems = [
    { label: <Link to="/">Home</Link>, key: "home" },
    { label: <Link to="/about">About</Link>, key: "about" },
    { label: <Link to="/adventures">Adventures</Link>, key: "adventures" },
    { label: <Link to="/contact-us">Contact</Link>, key: "contact" },
  token&&  { label:  <UserIcon />, key: "usericon" },

    ...(token
      ? [] // When logged in, UserIcon is displayed
      : [
          {
            label: (
              <Link to="/login" style={{ fontWeight: "bold" }}>
                Login
              </Link>
            ),
            key: "login",
          },
        ]),
  ];

  const menuItemsMobile = [
    { label: <Link to="/">Home</Link>, key: "home" },
    { label: <Link to="/my-account">My Account</Link>, key: "my-account" },
    { label: <Link to="/my-bookings"><CalendarOutlined style={{marginRight: "5px", fontSize: "initial"}}/>My Bookings</Link>, key: "my-bookings" },
    { label: <Link to="/favorites"><HeartOutlined style={{marginRight: "5px", fontSize: "initial"}}/>Favorites</Link>, key: "favorites" },

    ...(token
      ? [
          {
            label: (
              <Button
                type="text"
                onClick={handleLogout}
                style={{ color: "#b23b3b", fontWeight: "bold" }}
              >
                Logout
              </Button>
            ),
            key: "logout",
          },
        ]
      : [
          {
            label: (
              <Link to="/login" style={{ fontWeight: "bold" }}>
                Login
              </Link>
            ),
            key: "login",
          },
        ]),
  ];

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768;
      setIsMobileView(isMobile);

      if (!isMobile) {
        setIsDrawerOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <header className="navbar-container">
      <div className="navbar-content">
        {/* Logo */}
        <div className="navbar-logo">
          <Link to="/" className="navbar-logo-img">
            <img alt="tourismpk" src="/images/NEW-WHITE-ALL-YELLOW.png" />
          </Link>
        </div>

        {/* Desktop View */}
        {!isMobileView ? (
          <div className="navbar-desktop">
            <Menu
              mode="horizontal"
              items={menuItems}
              selectedKeys={[currentKey]}
            />
            {/* {token && <UserIcon />}  */}
          </div>
        ) : (
          // Mobile View
          <div className="navbar-menu-mobile">
            <Button
              type="text"
              icon={<MenuOutlined />}
              onClick={() => setIsDrawerOpen(true)}
              style={{ color: "var(--primary-color)" }}
            />
            <Drawer
              title="Menu"
              placement="right"
              onClose={() => setIsDrawerOpen(false)}
              open={isDrawerOpen}
              bodyStyle={{ padding: 0, backgroundColor: "#333333" }}
              headerStyle={{
                backgroundColor: "#1E1E1E",
                color: "var(--primary-color)",
              }}
            >
              <Menu
                mode="vertical"
                items={menuItemsMobile}
                selectedKeys={[currentKey]}
                style={{ backgroundColor: "#333333", color: "#D3D3D3" }}
              />
            </Drawer>
          </div>
        )}
      </div>
    </header>
  );
};

export default Navbar;
