import { axiosJSON } from "./AxiosInterceptor"; // Assuming AxiosInterceptor is properly configured for JSON requests

// Get all bookings
const getBookings = async () => {
  try {
    const response = await axiosJSON.get('/api/bookings');
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Get a booking by ID
const getBookingById = async (id) => {
  try {
    const endpoint = `/api/bookings/${id}`;
    const response = await axiosJSON.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getUserBookings = async () => {
  try {
    const endpoint = `/api/bookings/by-user`; // Backend endpoint for user favorites
    const response = await axiosJSON.get(endpoint);
    return response.data;
  } catch (error) {
    console.error("Error fetching favorite ads:", error);
    throw error;
  }
}

// Create a new booking
const createBooking = async (bookingData) => {
  try {
    const response = await axiosJSON.post('/api/bookings', bookingData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Update an existing booking
const updateBooking = async (id, bookingData) => {
  try {
    const endpoint = `/api/bookings/${id}`;
    const response = await axiosJSON.put(endpoint, bookingData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Delete a booking
const deleteBooking = async (id) => {
  try {
    const endpoint = `/api/bookings/${id}`;
    const response = await axiosJSON.delete(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Exporting the service
const BookingsService = {
  getBookings,
  createBooking,
  updateBooking,
  deleteBooking,
  getBookingById,
  getUserBookings,

};

export default BookingsService;
