import React, { forwardRef } from 'react';
import { Form, Input, Button } from 'antd';

const CityForm = forwardRef(({ editingCity, onFinish }, ref) => (
  <Form form={ref} layout="vertical" onFinish={onFinish}>
    <Form.Item
      name="name"
      label="City Name"
      rules={[{ required: true, message: 'Please input the city name!' }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="country"
      label="Country"
      rules={[{ required: true, message: 'Please input the country!' }]}
    >
      <Input />
    </Form.Item>
    <Form.Item name="province" label="Province">
      <Input />
    </Form.Item>
    <Form.Item name="division" label="Division">
      <Input />
    </Form.Item>
    <Form.Item name="district" label="District">
      <Input />
    </Form.Item>
    <Form.Item>
      <Button type="primary" htmlType="submit" block>
        {editingCity ? 'Update City' : 'Add City'}
      </Button>
    </Form.Item>
  </Form>
));

export default CityForm;
