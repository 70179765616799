import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { Typography, message } from "antd";
import List from "../../components/List/List";
import AdsService from "../../services/AdService";
import "../adventure/Adventure.css";
import LocalStorageService from "../../services/LocalStorage";


const { Title, Text } = Typography;

const CustomLoader = () => (
  <div className="custom-loader-container">
    <div
      style={{
        width: "100px",
        height: "100px",
        animation: "spinAnimation 1.5s linear infinite",
        backgroundImage: `url('/images/WB-Logo-Gif.gif')`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
    ></div>
  </div>
);

const Favorites = () => {
  const navigate = useNavigate();
  const token = LocalStorageService.getItem('token');

  const [items, setItems] = useState([]); // State to hold favorite ads
  const [loading, setLoading] = useState(false); // State for loading indicator

  // Fetch favorite ads when the component loads
  useEffect(() => {
    fetchFavorites();
  }, []);

  // Fetch favorite ads from the server
  const fetchFavorites = async () => {
    setLoading(true); // Show loader
    try {
      const data = await AdsService.getFavoriteAds();

      // Map the response to initialize the favorite state
      const ads = (data.data || []).map((favorite) => ({
        ...favorite.ad,
        isFavorited: true, // All fetched items are favorited
      }));
      setItems(ads); // Update state with fetched ads
    } catch (error) {
      message.error("Failed to fetch favorite ads. Please try again.");
      console.error("Error fetching favorite ads:", error);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  // Toggle the favorite status of an ad
  const toggleFavorite = async (adId) => {
    try {
      if (!token) {
        navigate('/login');
        return;
      }
      // Optimistically update UI
      const updatedItems = items.map((item) => {
        if (item._id === adId) {
          const newFavoriteState = !item.isFavorited;
          item.isFavorited = newFavoriteState; // Toggle favorite state
          // Show a message based on the new favorite state
          message.success(
            newFavoriteState ? "Added to favorites" : "Removed from favorites"
          );
        }
        return item;
      });

      setItems([...updatedItems]); // Update state

      // Persist favorite status change on the server
      await AdsService.toggleFavorite(adId);
    } catch (error) {
      message.error("Failed to update favorite status. Please try again.");
      console.error("Error toggling favorite:", error);
    }
  };

  // Render loading state
  if (loading) {
    return (
      <div className="loading-container">
        <CustomLoader />
      </div>
    );
  }

  // Render empty state if no favorite ads
  if (items.length === 0) {
    return (
      <div className="loading-container">
        <Text>No favorite ads found for the user.</Text>
      </div>
    );
  }

  // Render list of favorite ads
  return (
    <div className="container">
      <div className="navbar"></div>
      <div className="header">
        <Title className="title" level={2}>
          Your Favorites
        </Title>
        <Text type="secondary" className="subTitle">
          Browse your favorited adventures and activities.
        </Text>
      </div>
      <div className="listContainer">
        <List items={items} onToggleFavorite={toggleFavorite} />
      </div>
    </div>
  );
};

export default Favorites;
