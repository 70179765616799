import React, { useCallback, useState } from "react";
import {
  ContainerOutlined,
  GlobalOutlined,
  PoweroffOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, theme } from "antd";
import { Link, useNavigate, Outlet, useLocation } from "react-router-dom";
import "./Dashboard.css";
import LocalStorageService from "../../services/LocalStorage";

const { Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem(
    "User Management",
    "/dashboard/user-management",
    <UsergroupAddOutlined />
  ),
  getItem(
    "Cities Management",
    "/dashboard/cities-management",
    <GlobalOutlined />
  ),
  getItem("Organization", "/dashboard/organization", <TeamOutlined />),
  getItem("Ads Management", "/dashboard/ads-management", <ContainerOutlined />),
  getItem("Bookings Management", "/dashboard/bookings-management", <ContainerOutlined />),
  getItem("Contact Us", "/dashboard/contact-us", <ContainerOutlined />),


];

const Dashboard = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  const handleLogout = useCallback(() => {
    LocalStorageService.logout(navigate)

  }, [navigate]);

  const onMenuClick = ({ key }) => {
    navigate(key); // Navigate to the route specified in the key
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        theme="dark"
        style={{ position: "relative" }}
      >
        {/* Logo and Text */}
        <div className="navbar-logo">
          <Link to="/" className="navbar-logo-link">
            <img
              alt="TourismPk Logo"
              src={collapsed ? "/images/LOGO-YELLOW.png" : "/images/NEW-WHITE-ALL-YELLOW.png"}
              style={{
                width: collapsed ? "50px" : "150px",
                height: "auto",
                margin: "16px auto",
                display: "block",
              }}
            />
          </Link>
        </div>


        {/* Menu */}
        <Menu
          theme="dark"
          mode="inline"
          items={items}
          onClick={(e) => onMenuClick(e)} // Handle menu clicks, add your logic
          selectedKeys={[location.pathname]} // Highlight the active route
        />

        {/* Logout Button at the bottom */}
        <Button
          type="primary"
          danger
          icon={<PoweroffOutlined />}
          onClick={handleLogout}
          style={{
            position: "absolute",
            bottom: "4rem",
            left: "50%",
            transform: "translateX(-50%)",
            width: "90%",
          }}
        >
          {!collapsed && "Logout"}
        </Button>
      </Sider>

      {/* Main Content */}
      <Layout>
        <Content
          style={{
            margin: "24px 16px 0",
            padding: 24,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
            height: "calc(100vh - 100px)", // Calculate available space for content
            overflow: "hidden", // Prevent overflow beyond the content area
          }}
        >
          <div className="outlet" style={{ overflow: "auto", height: "100%" }}>
            <Outlet />
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>TourismPk ©2024</Footer>
      </Layout>
    </Layout>
  );
};

export default Dashboard;

// import React, { useCallback, useState } from "react";
// import {
//   ContainerOutlined,
//   GlobalOutlined,
//   PoweroffOutlined,
//   TeamOutlined,
//   UsergroupAddOutlined,
// } from "@ant-design/icons";
// import { Button, Layout, Menu, theme } from "antd";
// import { Link, useNavigate, Outlet, useLocation } from "react-router-dom";
// import "./Dashboard.css";
// import LocalStorageService from "../../services/LocalStorage";

// const { Content, Footer, Sider } = Layout;

// function getItem(label, key, icon, children) {
//   return {
//     key,
//     icon,
//     children,
//     label,
//   };
// }

// const items = [
//   {
//     key: "user-management",
//     label: "User Management",
//     children: [
//       getItem("User Management", "/dashboard/user-management", <UsergroupAddOutlined />),
//     ],
//   },
//   {
//     key: "management",
//     label: "Management",
//     children: [
//       getItem("Cities Management", "/dashboard/cities-management", <GlobalOutlined />),
//       getItem("Organization", "/dashboard/organization", <TeamOutlined />),
//       getItem("Ads Management", "/dashboard/ads-management", <ContainerOutlined />),
//       getItem("Bookings Management", "/dashboard/bookings-management", <ContainerOutlined />),
//     ],
//   },
// ];

// const Dashboard = () => {
//   const [collapsed, setCollapsed] = useState(false);
//   const {
//     token: { colorBgContainer, borderRadiusLG },
//   } = theme.useToken();

//   const navigate = useNavigate();
//   const location = useLocation();

//   const handleLogout = useCallback(() => {
//     LocalStorageService.logout(navigate);
//   }, [navigate]);

//   const onMenuClick = ({ key }) => {
//     navigate(key);
//   };

//   return (
//     <Layout style={{ minHeight: "100vh" }}>
//       <Sider
//         collapsible
//         collapsed={collapsed}
//         onCollapse={(value) => setCollapsed(value)}
//         theme="dark"
//         style={{ position: "relative" }}
//       >
//         {/* Logo */}
//         <div className="navbar-logo">
//           <Link to="/" className="navbar-logo-link">
//             <img
//               alt="TourismPk Logo"
//               src={collapsed ? "/images/LOGO-YELLOW.png" : "/images/NEW-WHITE-ALL-YELLOW.png"}
//               style={{
//                 width: collapsed ? "50px" : "150px",
//                 height: "auto",
//                 margin: "16px auto",
//                 display: "block",
//               }}
//             />
//           </Link>
//         </div>

//         {/* Categorized and Collapsible Menu */}
//         <Menu
//           theme="dark"
//           mode="inline"
//           selectedKeys={[location.pathname]}
//           onClick={onMenuClick}
//         >
//           {items.map((category) => (
//             <Menu.SubMenu key={category.key} title={category.label}>
//               {category.children.map((child) => (
//                 <Menu.Item key={child.key} icon={child.icon}>
//                   {child.label}
//                 </Menu.Item>
//               ))}
//             </Menu.SubMenu>
//           ))}
//         </Menu>

//         {/* Logout Button */}
//         <Button
//           type="primary"
//           danger
//           icon={<PoweroffOutlined />}
//           onClick={handleLogout}
//           style={{
//             position: "absolute",
//             bottom: "4rem",
//             left: "50%",
//             transform: "translateX(-50%)",
//             width: "90%",
//           }}
//         >
//           {!collapsed && "Logout"}
//         </Button>
//       </Sider>

//       {/* Main Content */}
//       <Layout>
//         <Content
//           style={{
//             margin: "24px 16px 0",
//             padding: 24,
//             background: colorBgContainer,
//             borderRadius: borderRadiusLG,
//             height: "calc(100vh - 100px)",
//             overflow: "hidden",
//           }}
//         >
//           <div className="outlet" style={{ overflow: "auto", height: "100%" }}>
//             <Outlet />
//           </div>
//         </Content>
//         <Footer style={{ textAlign: "center" }}>TourismPk ©2024</Footer>
//       </Layout>
//     </Layout>
//   );
// };

// export default Dashboard;
