// NotFound.js
import React from 'react';

const NotFound = () => {
    return (
        <div className="not-found" style={{ textAlign: 'center', color: '#f5f5f5' }}>
            <h2 style={{ fontSize: '36px', color: 'var(--primary-color)' }}>404 - Page Not Found</h2>
            <p>The page you are looking for does not exist.</p>
        </div>
    );
};

export default NotFound;