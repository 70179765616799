import { axiosJSON, axiosMultipart } from "./AxiosInterceptor";

// Get all ads
const getAds = async (selectFields) => {
  try {
    const filters = {
      select: JSON.stringify(selectFields)
    };
    const response = await axiosJSON.get('/api/adventures', { params: filters });
    return response.data;
  } catch (error) {
    throw error;
  }
};
// Get ads by city
const getAdsByCity = async (cityId) => {
  try {
    const endpoint = cityId ? `/api/adventures/city/${cityId}` : `/api/adventures/city`;
    const response = await axiosJSON.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
};
// Get a single ad by ID
const getAdById = async (id) => {
  try {
    const endpoint = `/api/adventures/detail/${id}`;
    const response = await axiosJSON.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
};
// Get favorite ads for the logged-in user
const getFavoriteAds = async () => {
  try {
    const endpoint = `/api/favorites`; // Backend endpoint for user favorites
    const response = await axiosJSON.get(endpoint);
    return response.data;
  } catch (error) {
    console.error("Error fetching favorite ads:", error);
    throw error;
  }
}
const toggleFavorite = async (adId) => {
  try {
    const response = await axiosJSON.post(`/api/favorites/toggle`, { adId });
    return response.data;
  } catch (error) {
    console.error("Error toggling favorite:", error);
    throw error;
  }
};

// Create a new ad
const createAd = async (adData) => {
  try {
    const response = await axiosMultipart.post('/api/adventures', adData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Update an ad
const updateAd = async (id, adData) => {
  try {
    const response = await axiosMultipart.put(`/api/adventures/${id}`, adData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Delete an ad
const deleteAd = async (id) => {
  try {
    const response = await axiosJSON.delete(`/api/adventures/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const AdsService = {
  getAds,
  createAd,
  updateAd,
  deleteAd,
  getAdsByCity,
  getAdById,
  getFavoriteAds,
  toggleFavorite
};

export default AdsService;
