// npm install react-leaflet leaflet leaflet-routing-machine 
import React from 'react';
import { MapContainer, TileLayer, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const LeafletMap = ({ locations }) => {
    const positions = locations

    return (
        <div style={{ width: '100%', height: '30rem' }}>
            <MapContainer center={[33.6844, 73.0479]} zoom={6} style={{ height: '100%', width: '100%' }}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Polyline positions={positions} color="red" />
            </MapContainer>
        </div>
    );
};

export default LeafletMap;
