import React, { useState } from 'react';
import { message } from 'antd';
import SecurityForm from './Form';
import AuthService from "../../../services/AuthService"; // Assuming this is the service file path
import LocalStorageService from '../../../services/LocalStorage';

const Security = () => {
  const [loading, setLoading] = useState(false);
  const token = LocalStorageService.getItem('token');
  const handleFinish = async (values) => {
    try {
      setLoading(true);
      // Simulate API call for password update
      await AuthService.changePassword(token, values.currentPassword, values.newPassword);
      message.success('Password updated successfully');
    } catch (error) {
      message.error('Failed to update password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '40%', margin: '0 auto' }}>
      <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '4px' }}>Security</h2>
      <p style={{ fontSize: '14px', color: '#888', marginBottom: '24px' }}>
        Update your password to keep your account secure.
      </p>
      <SecurityForm onFinish={handleFinish} loading={loading} />
    </div>
  );
};

export default Security;
