import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../Auth.css';
import AuthService from '../../../services/AuthService';
import { jwtDecode } from 'jwt-decode';
// import notificationService from "../../services/NotificationService";
import { Button, ConfigProvider, Divider, Flex, Form, Input, message, notification, Spin } from 'antd';
import { AppleFilled } from '@ant-design/icons';
import { useGoogleLogin } from '@react-oauth/google';
import { ThemeContext } from '../../../contexts/ThemeContext';
import LocalStorageService from '../../../services/LocalStorage';


function Login() {

  const [form] = Form.useForm(); // Ant Design form instance
  const [loading, setLoading] = useState(false);
  const [spinner, setSpinner] = useState(false)
  const { updateAppearance } = useContext(ThemeContext);

  const navigate = useNavigate();
  const token = LocalStorageService.getItem('token');




  useEffect(() => {
    if (token) {
      navigate('/');
      return;
    }


  }, [token, navigate]);

  const login = async (values) => {
    try {
      setLoading(true); // Start loading
      const { email, password } = values;
      const { token } = await AuthService.login(email, password);
      const userData = jwtDecode(token);
      LocalStorageService.setItem('token', token);
      LocalStorageService.setItem('appearancePreference', userData?.data?.appearance_preference);
      updateAppearance(userData?.data?.appearance_preference);
      message.success(`Appearance preference updated to ${userData?.data?.appearance_preference}`);
      LocalStorageService.setItem('user', JSON.stringify(userData));

      if (userData?.data?.verified_email) {
        // notificationService.sendNotification(`Congratulations ${userData?.data.full_name}!`, {
        //   body: "You have successfully logged in 🎉",
        //   icon: "/icon.png", // Path to your notification icon
        // });
        // notification.success({
        //   message: 'Login Successful',
        //   description: `Welcome back, ${userData?.data.full_name}! 🎉`,
        //   duration: 3,
        // });
        navigate('/');
      } else {
        notification.info({
          message: 'Verification Required',
          description: 'Please verify your email to proceed.',
          duration: 3,
        });
        navigate('/verification');
      }
    } catch (err) {
      console.log(err);

      notification.error({
        message: 'Login Failed',
        description: err || 'An error occurred while logging in. Please try again.',
        duration: 3,
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const handleSubmit = async (values) => {
    await login(values);
  };
  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await form.validateFields(['email']);
    } catch (validationError) {
      notification.warning({
        message: 'Invalid Email',
        description: 'Please enter invalid email.',
        duration: 3
      })
      return;
    }

    try {
      setSpinner(true)
      const email = form.getFieldValue('email');
      await AuthService.request_reset_password(email);
      notification.success({
        message: 'Sent!',
        description: `Reset password link is sent to your email! 🎉`,
        duration: 3,
      });
      // const userData = jwtDecode(token);
      // LocalStorageService.setItem('token', token);
      // LocalStorageService.setItem('user', JSON.stringify(userData));
      //   navigate('/reset-password');
    } catch (err) {
      notification.error({
        message: 'Reset Password Reuest Failed',
        description: err || 'An error occurred while sending request. Please try again.',
        duration: 3,
      });
    } finally {
      setSpinner(false)
    }
  };
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        // Extracting access token from the response
        const { access_token } = tokenResponse;

        // You can use the access_token to fetch user details from Google's API
        const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch Google user information');
        }

        const userData = await response.json();

        // Sending Google user data to the backend for login/registration
        const backendResponse = await AuthService.googleLogin({
          email: userData.email,
          name: userData.name,
          given_name: userData.given_name,
          family_name: userData.family_name,
          picture: userData.picture,
        });

        if (backendResponse.token) {
          // Save token and user details locally
          LocalStorageService.setItem('token', backendResponse.token);
          LocalStorageService.setItem('user', JSON.stringify({ data: backendResponse.user }));

          notification.success({
            message: 'Google Login Successful',
            description: `Welcome, ${backendResponse.user.full_name}! 🎉`,
            duration: 3,
          });

          navigate('/');
        }
      } catch (error) {
        console.error('Google login error:', error);
        notification.error({
          message: 'Google Login Failed',
          description: error.message || 'An error occurred during Google login. Please try again later.',
          duration: 3,
        });
      }
    },
    onError: (error) => {
      console.error('Google login error:', error);
      notification.error({
        message: 'Google Login Failed',
        description: 'Google login was unsuccessful. Please try again later.',
        duration: 3,
      });
    },
  });


  const handleAppleLogin = async () => {
    try {
      if (!window.AppleID) {
        throw new Error('AppleID JS SDK not loaded. Please include the SDK in your index.html.');
      }

      // Initialize Apple Sign-In with your clientId and redirect URI
      window.AppleID.auth.init({
        clientId: 'YOUR_APPLE_CLIENT_ID', // Replace with your Apple Client ID
        scope: 'email name', // Request email and name
        redirectURI: 'YOUR_REDIRECT_URI', // Replace with your redirect URI
        usePopup: true, // Use popup to handle login
      });

      // Trigger the Sign-In process
      const response = await window.AppleID.auth.signIn();

      const { email, fullName, identityToken } = response.user;

      // Send Apple user data to the backend
      const backendResponse = await AuthService.appleLogin({
        email,
        full_name: fullName ? `${fullName.givenName} ${fullName.familyName}` : null,
        identity_token: identityToken,
      });

      if (backendResponse.token) {
        // Save the token and user details locally
        LocalStorageService.setItem('token', backendResponse.token);
        LocalStorageService.setItem('user', JSON.stringify(backendResponse.user));

        notification.success({
          message: 'Apple Login Successful',
          description: `Welcome, ${backendResponse.user.full_name}! 🎉`,
          duration: 3,
        });
        navigate('/');
      }
    } catch (err) {
      notification.error({
        message: 'Apple Login Failed',
        description: err.message || 'An error occurred during Apple login. Please try again later.',
        duration: 3,
      });
    }
  };


  return (
    <ConfigProvider theme={{

      components: {
        Button: {
          primaryColor: "Black"
        }, Form: {
          itemMarginBottom: "8px",

        }
      }
    }}>

      <div className="main-container">
        <h1>Login</h1>
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          initialValues={{
            email: '',
            password: '',
          }}
          onFinish={handleSubmit} // Trigger on successful form submission
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please enter your email!',
              },
              {
                type: 'email', // Email validation
                message: 'Please enter a valid email address!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please enter your password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Spin spinning={spinner}>
            <Form.Item>
              <Flex justify="end" align="center">
                <Link to="/" onClick={handleResetPassword}>Forgot Password?</Link>
              </Flex>
            </Form.Item>
          </Spin>
          <Form.Item>
            <Button size="large"
              htmlType="submit"
              type="primary"
              block
              loading={loading} // Loading spinner on button
            >
              {loading ? 'Logging In...' : 'Login'}
            </Button>
          </Form.Item>
        </Form>
        {/* {error && <p style={{ color: 'red' }}>{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="username">Email:</label>
            <input
              type="email"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="login-btn">Log In</button>

        </form> */}
        <p className="footer-text">
          Don’t have an account? <Link to="/register">Register here</Link>
        </p>
        <div>
          <Divider>OR</Divider>
          <div style={{ textAlign: "center", marginTop: "1rem" }}>
            <Button
              type="default"
              block
              size="large"
              icon={
                <img
                  src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
                  alt="Google Icon"
                  style={{ width: 20, marginRight: 8 }}
                />
              } // Add colorful Google icon
              style={{
                backgroundColor: "white",
                color: "black", // Black text
                marginBottom: "1rem",
                border: "1px solid #ddd", // Optional for a more defined button
              }}
              onClick={googleLogin}
            >
              Sign in with Google
            </Button>
            <Button
              type="default"
              block
              size="large"
              icon={<AppleFilled />}
              onClick={handleAppleLogin}
            >
              Sign in with Apple
            </Button>
          </div>
        </div>

      </div>


    </ConfigProvider >
  );
}

export default Login;
