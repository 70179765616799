import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography, message } from "antd";
import List from "../../components/List/List";
import AdsService from "../../services/AdService";
import "./Adventure.css";
import LocalStorageService from "../../services/LocalStorage";

const { Title, Text } = Typography;

// Custom Loader Component
const CustomLoader = () => (
  <div className="custom-loader-container">
    <div
      style={{
        width: "100px",
        height: "100px",
        animation: "spinAnimation 1.5s linear infinite",
        backgroundImage: `url('/images/WB-Logo-Gif.gif')`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
    ></div>
  </div>
);

const Adventure = () => {
  const navigate = useNavigate();
  const token = LocalStorageService.getItem('token');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cityId = queryParams.get("search");
  const [items, setItems] = useState([]); // State to hold ads
  const [loading, setLoading] = useState(false); // State for loading indicator

  useEffect(() => {
    fetchAds(cityId);
  }, [cityId]);

  const fetchAds = async (cityId) => {
    setLoading(true);
    try {
      const data = await AdsService.getAdsByCity(cityId);
      const ads = (data.data || []).map((ad) => ({
        ...ad,
        isFavorited: ad.favorite, // Initialize favorite state from data
      }));
      setItems(ads);
    } catch (error) {
      message.error("Failed to fetch ads. Please try again.");
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleFavorite = async (adId) => {
    try {
      if (!token) {
        navigate('/login');
        return;
      }
      // Optimistically toggle favorite state
      const updatedItems = items.map((item) => {
        if (item._id === adId) {
          const newFavoriteState = !item.isFavorited;
          item.isFavorited = newFavoriteState; // Update favorite state
          // Show a message based on the new favorite state
          message.success(
            newFavoriteState ? "Added to favorites" : "Removed from favorites"
          );
        }
        return item;
      });

      setItems([...updatedItems]); // Update UI with optimistic state

      // Persist favorite state change in backend
      await AdsService.toggleFavorite(adId);
    } catch (error) {
      message.error("Failed to update favorite status. Please try again.");
      console.error("Error updating favorite status:", error);
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <CustomLoader />
      </div>
    );
  }

  if ((!items && !loading) || items.length === 0) {
    return (
      <div className="loading-container">
        <Text>No adventures found for the selected city.</Text>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="navbar"></div>
      <div className="header">
        <Title className="title" level={2}>
          Adventures
        </Title>
        <Text type="secondary" className="subTitle">
          Explore the best activities for thrill-seekers!
        </Text>
      </div>

      <div className="listContainer">
        <List items={items} onToggleFavorite={toggleFavorite} />
      </div>
    </div>
  );
};

export default Adventure;
