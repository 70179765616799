import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css'; // Ensure it doesn't override font settings unless necessary
import reportWebVitals from './reportWebVitals';
import App from "./App";
import { ConfigProvider } from 'antd';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ThemeProvider, ThemeContext } from '../src/contexts/ThemeContext';

const RootComponent = () => (
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <ThemeProvider>
        <ThemeContext.Consumer>
          {({ currentTheme }) => (
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: '#ffc60c',
                  colorLink: '#ffc60c',
                  // fontFamily:"BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans'"
                },
                algorithm: currentTheme,
              }}
            >
              <App />
            </ConfigProvider>
          )}
        </ThemeContext.Consumer>
      </ThemeProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);

reportWebVitals();
