import React from "react";
import { Typography, List, Divider, Rate } from "antd";
import "./InformationDetail.css";

const { Text, Title, Paragraph } = Typography;

const InformationDetail = ({ adDetails }) => {
  return (
    <div className="additional-details">
      <Divider />
      <Title level={4}>Meeting Point</Title>
      <Paragraph>{adDetails?.meeting_point || "Not Specified"}</Paragraph>

      <Title level={4}>Departure & Return Location</Title>
      <Paragraph>
        Departure: {adDetails?.departure || "Not Specified"} <br />
        Return: {adDetails?.return_location || "Not Specified"}
      </Paragraph>

      <Divider />
      <Title level={4}>Contact Information</Title>
      <Paragraph>
        Phone: {adDetails?.contact_information?.phone || "Not Provided"} <br />
        Email: {adDetails?.contact_information?.email || "Not Provided"}
      </Paragraph>

      <Divider />
      <Title level={4}>Price Includes</Title>
      <List
        dataSource={adDetails?.price_includes || []}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />

      <Title level={4}>Price Excludes</Title>
      <List
        dataSource={adDetails?.price_excludes || []}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />

      <Divider />
      <Title level={4}>Highlights</Title>
      <Paragraph>{adDetails?.highlights || "No highlights provided."}</Paragraph>

      <Divider />
      <Title level={4}>Hotels</Title>
      {adDetails?.hotels?.length ? (
        <List
          dataSource={adDetails.hotels}
          renderItem={(hotel) => (
            <List.Item>
              <Text strong>{hotel.city}:</Text> {hotel.hotel || "Not specified"}
            </List.Item>
          )}
        />
      ) : (
        <Paragraph>No hotel information available.</Paragraph>
      )}

      <Divider />
      <Title level={4}>Organization Reviews</Title>
      {adDetails?.reviews?.length ? (
        <List
          dataSource={adDetails.reviews}
          renderItem={(review) => (
            <List.Item>
              <Text strong>{review.reviewer_name}:</Text> {review.comment} <br />
              <Rate
                disabled
                value={review.rating || 0}
                style={{ fontSize: "14px", marginTop: "0.5rem" }}
              />
              <Text type="secondary" style={{ marginLeft: "1rem" }}>
                {new Date(review.created_at).toLocaleDateString()}
              </Text>
            </List.Item>
          )}
        />
      ) : (
        <Paragraph>No reviews available yet.</Paragraph>
      )}

      <Divider />
      <Title level={4}>Terms & Conditions</Title>
      <Paragraph>
        {adDetails?.terms_and_conditions || "No terms and conditions specified."}
      </Paragraph>
    </div>
  );
};

export default InformationDetail;
