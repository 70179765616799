import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../../services/AuthService';
import UserIcon from '../../../components/UserIcon/UserIcon';
import './Verification.css';

import { jwtDecode } from 'jwt-decode';
import { ConfigProvider, Form, Input, notification } from 'antd';
import LocalStorageService from '../../../services/LocalStorage';

function Verification() {
  const [form] = Form.useForm(); // Ant Design form instance
  const [loading, setLoading] = useState(false); // Loading state for the submit button
  const navigate = useNavigate();
  const token = LocalStorageService.getItem('token');

  useEffect(() => {
    if (token) {
      try {
        const decodedData = jwtDecode(token);

        if (decodedData?.data?.verified_email === false) {
          navigate('/verification');
        } else {
          navigate('/');
        }
      } catch (error) {
        console.error("Invalid token:", error);
      }
    } else {
      navigate('/');
    }
  }, [navigate, token]);

  const handleSubmit = async (values) => {
    setLoading(true); // Start loading
    try {
      const code = values;
      const userData = JSON.parse(LocalStorageService.getItem('user'));
      const email = userData?.data.email;

      const { token } = await AuthService.verifyCode({ email: email }, code);
      if (token) {
        LocalStorageService.setItem('token', token);
        const userData = jwtDecode(token);
        LocalStorageService.setItem('user', JSON.stringify(userData));
        if (userData?.data?.verified_email) {
          notification.success({
            message: 'Verification Successful',
            description: 'Your email has been verified successfully!',
            duration: 3,
          });
          navigate('/');
        } else {
          navigate('/verification');
        }
      }
    } catch (err) {
      notification.error({
        message: 'Verification Failed',
        description: err?.message || 'Verification failed. Please try again.',
        duration: 3,
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleResend = async () => {
    try {
      const userData = JSON.parse(LocalStorageService.getItem('user'));
      const email = userData?.data.email;

      await AuthService.resendCode(email);
      notification.success({
        message: 'Code Resent',
        description: 'Verification code has been resent to your email.',
        duration: 3,
      });
    } catch (err) {
      notification.error({
        message: 'Resend Failed',
        description: err?.message || 'Failed to resend the verification code.',
        duration: 3,
      });
    }
  };
  const onChange = (text) => {
    console.log(text);
    
    handleSubmit(text)
  };
  const sharedProps = {
    onChange,
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            primaryColor: 'Black',
          }, Form: {
            itemMarginBottom: "8px",

          }
        },
      }}
    >
      <div className='user-fa'>
        <UserIcon />
      </div>

      <div className="main-container">
        <h1>Verify Code</h1>
        <Form
          form={form}
          name="verificationForm"
          layout="vertical"
          autoComplete="off"
          onFinish={handleSubmit} // Trigger on successful form submission
        >
          <Form.Item
            label=""
            name="code"
            rules={[
              {
                required: true,
                message: 'Please enter the verification code!',
              },
            ]}
          >
            <Input.OTP disabled={loading} formatter={(str) => str.toUpperCase()} {...sharedProps} 
            />
          </Form.Item>
        </Form>
        <p className="footer-text">
          Didn't receive the code?{' '}
          <a onClick={handleResend} href="#resend">
            Click here
          </a>
        </p>
      </div>
    </ConfigProvider>
  );
}

export default Verification;
