// src/components/DashboardHeader.jsx
import React from 'react';
import { Input, Button, Upload } from 'antd';
import { SearchOutlined, UploadOutlined } from '@ant-design/icons';

const DashboardHeader = ({
  title,
  searchPlaceholder,
  onSearchChange,
  buttons = [],
  uploadProps,
}) => {
  return (
    <div
      style={{
        marginBottom: 16,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <h1>{title}</h1>
      <div style={{ display: 'flex', gap: '16px' }}>
        <Input
          placeholder={searchPlaceholder}
          prefix={<SearchOutlined />}
          onChange={onSearchChange}
          style={{ width: 200 }}
        />
        {buttons.map((button, index) => (
          <React.Fragment key={index}>
            {button}
          </React.Fragment>
        ))}
        {uploadProps && (
          <Upload {...uploadProps}>
            <Button type="default" icon={<UploadOutlined />}>
              Import
            </Button>
          </Upload>
        )}
      </div>
    </div>
  );
};

export default DashboardHeader;
