import { axiosJSON, axiosMultipart } from "./AxiosInterceptor";


/**
 * Fetch all cities
 * @param {string} query - Optional search query
 * @returns {Promise} - List of cities
 */
// const getCities = async (query = '') => {
//   try {
//     const response = await axios.get(`${API_URL}/api/cities`, {
//       params: { query },
//       headers: {
//         Authorization: `Bearer ${LocalStorageService.getItem('token')}`, // Add authorization if required
//       },
//     });
//     return response.data.data; // Assuming the API response includes a `data` field for cities
//   } catch (error) {
//     throw error.response?.data?.message || 'Failed to fetch cities';
//   }
// };

const getCities = async (page = 1, limit = 15) => {
  try {
    const response = await axiosJSON.get('/api/cities', { params: { page, limit } });
    return response.data; // Return paginated data
  } catch (error) {
    throw error.response?.data?.message || 'Failed to fetch cities';
  }
};


const searchAllCities = async (query = '') => {
  try {
    const response = await axiosJSON.get('/api/cities/search', { params: { query } });
    return response.data; // Assuming the API response includes a `data` field for cities
  } catch (error) {
    throw error.response?.data?.message || 'Failed to fetch cities';
  }
};
/**
 * Create a new city
 * @param {Object} cityData - City details to be added
 * @returns {Promise} - Created city data
 */
const addCity = async (cityData) => {
  try {
    const response = await axiosMultipart.post('/api/cities', cityData);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to create city';
  }
};

/**
 * Update an existing city
 * @param {string} id - City ID
 * @param {Object} cityData - Updated city details
 * @returns {Promise} - Updated city data
 */
const updateCity = async (id, cityData) => {
  try {
    const response = await axiosJSON.put(`/api/cities/${id}`, cityData);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to update city';
  }
};

/**
 * Delete a city
 * @param {string} id - City ID
 * @returns {Promise} - Response of the API
 */
const deleteCity = async (id) => {
  try {
    const response = await axiosJSON.delete(`/api/cities/${id}`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to delete city';
  }
};

/**
 * Update the active status of a city
 * @param {string} id - City ID
 * @param {boolean} is_active - Active status to update
 * @returns {Promise} - Updated city data
 */
// const updateActiveStatus = async (id, is_active) => {
//   try {
//     const response = await axios.patch(`${API_URL}/api/cities/${id}/active-status`, { is_active }, {
//       headers: {
//         Authorization: `Bearer ${LocalStorageService.getItem('token')}`, // Add authorization if required
//       },
//     });
//     return response.data;
//   } catch (error) {
//     throw error.response?.data?.message || 'Failed to update active status';
//   }
// };

const CitiesService = {
  getCities,
  addCity,
  updateCity,
  deleteCity,
  // updateActiveStatus,
  searchAllCities,
};
export default CitiesService;