import axios from 'axios';
import LocalStorageService from './LocalStorage';

const API_URL = process.env.REACT_APP_SERVER_API_URL;

const register = async (body) => {
  try {

    const response = await axios.post(`${API_URL}/api/users/register`,body );

    return response;
  } catch (error) {
    throw error.response?.data?.message || 'Registration failed';
  }
};

const login = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/api/users/login`, { email, password });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Login failed';
  }
};
const googleLogin = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/api/users/google-login`, {
      email: userData.email,
      name: userData.name,
      given_name: userData.given_name,
      family_name: userData.family_name,
      picture: userData.picture,
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || "Google login failed";
  }
};

const appleLogin = async (appleUser) => {
  try {
    const response = await axios.post(`${API_URL}/api/users/apple-login`, appleUser);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || "Apple login failed";
  }
};

const request_reset_password = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/api/users/request_reset_password`, { email, password });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Reset Password Request failed';
  }
};

const resetPassword = async (token, new_password) => {
  try {
        // Get the current URL
    const url = new URL(window.location.href);

    // Get the token from the URL query parameters
    const token = url.searchParams.get('token');
    const response = await axios.post(
      `${API_URL}/api/users/reset_password`,
      { new_password },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Forget Reset Password failed';
  }
};
const changePassword = async (token, current_password, new_password) => {
  try {
    const response = await axios.post(
      `${API_URL}/api/users/change_password`,
      { current_password, new_password },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Change Password failed';
  }
};

// New function to verify the code
const verifyCode = async (user, verification_code) => {
  try {
    const response = await axios.post(`${API_URL}/api/verify/email_code`, { user, verification_code }, { headers: { Authorization: `Bearer ${LocalStorageService.getItem('token')}` } });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Verification failed';
  }
};

// New function to resend verification code
const resendCode = async (email) => {
  try {
    const response = await axios.post(`${API_URL}/api/verify/resend_email`, { email }, { headers: { Authorization: `Bearer ${LocalStorageService.getItem('token')}` } });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Resend failed';
  }
};

//User management
const getUsers = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/users`, {
      headers: { Authorization: `Bearer ${LocalStorageService.getItem('token')}` },
    });
    return response.data; // Return user data from the API
  } catch (error) {
    throw error.response?.data?.message || 'Failed to fetch users';
  }
};

const deleteUser = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/api/users/delete_user/${id}`, {
      headers: { Authorization: `Bearer ${LocalStorageService.getItem('token')}` },
    });
    return response.data; // Return success message
  } catch (error) {
    throw error.response?.data?.message || 'Failed to delete user';
  }
};

const getUserByEmail = async (email) => {
  try {
    const response = await axios.get(`${API_URL}/api/users/${email}`, {
      headers: { Authorization: `Bearer ${LocalStorageService.getItem('token')}` },
    });
    return response.data.data; // Return success message
  } catch (error) {
    throw error.response?.data?.message || 'Failed to delete user';
  }
};

const updateUser = async (email, data) => {
  try {
    // Check if data is already FormData or create a new one
    let formData;
    if (data instanceof FormData) {
      formData = data;
    } else {
      formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
    }

    const response = await axios.put(`${API_URL}/api/users/update/${email}`, formData, {
      headers: {
        Authorization: `Bearer ${LocalStorageService.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    throw error.response?.data?.message || "Failed to update user";
  }
};


const updateAppearancePreference = async (email, data) => {
  try {
    const response = await axios.put(`${API_URL}/api/users/appearance_preference/${email}`, data, {
      headers: { Authorization: `Bearer ${LocalStorageService.getItem("token")}` },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || "Failed to update user";
  }

};


const addUser = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/api/users/add_user`, data, {
      headers: { Authorization: `Bearer ${LocalStorageService.getItem("token")}` },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || "Failed to add user";
  }
};


const AuthService = {
  login,
  register,
  verifyCode,
  resendCode,
  request_reset_password,
  resetPassword,
  googleLogin,
  appleLogin,
  getUsers,
  deleteUser,
  updateUser,
  addUser,
  changePassword,
  getUserByEmail,
  updateAppearancePreference
};

export default AuthService;
