import { Outlet, Navigate } from "react-router-dom";
import LocalStorageService from "../services/LocalStorage";

// Mock function to get the user's role (replace with your actual logic)
const getUserRole = () => JSON.parse(localStorage?.getItem("user"))?.data?.role; // 'admin', 'user', 'organization'

const ProtectedRoutes = ({ allowedRoles = [] }) => {
    const token = LocalStorageService.getItem("token");
    const userRole = getUserRole();
    if (!token) {
        return <Navigate to="/login" replace />;
    }

    if (allowedRoles.length > 0 && !allowedRoles.includes(userRole)) {
        return <Navigate to="/not-authorized" replace />;
    }

    return <Outlet />;
};

export default ProtectedRoutes;
