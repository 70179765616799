const setItem = (key, value) => {
    localStorage.setItem(key, value)
}

const getItem = (key) => {
    const item = localStorage.getItem(key)
    return item;
}

const removeItem = (key) => {
    localStorage.removeItem(key)
}

const logout = (navigate) => {
    removeItem('token')
    removeItem('user')
    removeItem('appearancePreference')
    navigate('/login');
}


const LocalStorageService = {
    setItem,
    getItem,
    removeItem,
    logout
};

export default LocalStorageService;
