import axios from 'axios';
import { Navigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import LocalStorageService from './LocalStorage';

const API_URL = process.env.REACT_APP_SERVER_API_URL || "http://localhost:5000";

// JSON instance
const axiosJSON = axios.create({
    baseURL: API_URL,
    headers: { 'Content-Type': 'application/json' },
});

// Multipart instance
const axiosMultipart = axios.create({
    baseURL: API_URL,
    headers: { 'Content-Type': 'multipart/form-data' },
});

// Token attachment interceptor
const attachToken = (config) => {
    const token = LocalStorageService.getItem('token');
    if (!token) {
        console.warn("No token found.");
        return config;
    }

    let decodedData;
    try {
        decodedData = jwtDecode(token);
    } catch (error) {
        console.error("Invalid token.");
        LocalStorageService.logout();
        return config;
    }

    const currentTime = Date.now() / 1000;
    if (decodedData.exp < currentTime) {
        console.warn("Token has expired.");
        LocalStorageService.logout();
        return config;
    }

    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
};

// Error handling for responses
const handleResponseError = (error) => {
    if (error.response) {
        if (error.response.status === 401) {
            console.error('Unauthorized access! Redirecting...');
            Navigate("/login"); // Replace with a proper redirection mechanism.
        } else if (error.response.status === 500) {
            console.error('Server error:', error.response.data.message);
        }
    }
    return Promise.reject(error.response?.data?.message || 'Something went wrong!');
};

// Attach interceptors
axiosJSON.interceptors.request.use(attachToken, (error) => Promise.reject(error));
axiosMultipart.interceptors.request.use(attachToken, (error) => Promise.reject(error));

axiosJSON.interceptors.response.use((response) => response, handleResponseError);
axiosMultipart.interceptors.response.use((response) => response, handleResponseError);

export { axiosJSON, axiosMultipart };
