import React, { useState, useEffect } from 'react';
import { Table, message, Modal, Button } from 'antd';
import ContactService from '../../../services/ContactService';
import DashboardHeader from '../../../components/DashboardHeader';

const ContactManagement = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedContact, setSelectedContact] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Fetch contacts
  const fetchContacts = async () => {
    try {
      setLoading(true);
      const response = await ContactService.getContacts();
      setContacts(response.data || []);
    } catch (error) {
      message.error(error || 'Failed to load contacts');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
      fetchContacts();
  }, []);

  const handleMarkAsResolved = async (id) => {
    try {
      setLoading(true);
      await ContactService.updateContact(id, { is_resolved: true });
      message.success('Contact marked as resolved');
      fetchContacts();
    } catch (error) {
      message.error(error || 'Failed to update contact');
    } finally {
      setLoading(false);
    }
  };

  const openModal = (contact) => {
    setSelectedContact(contact);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setSelectedContact(null);
    setIsModalVisible(false);
  };

  const filteredContacts = contacts.filter((contact) =>
    contact?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
    contact?.email?.toLowerCase().includes(searchText.toLowerCase()) ||
    contact?.phone?.toLowerCase().includes(searchText.toLowerCase()) ||
    contact?.message?.toLowerCase().includes(searchText.toLowerCase())
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      ellipsis: true,
    },
    {
      title: 'Resolved',
      dataIndex: 'is_resolved',
      key: 'is_resolved',
      render: (is_resolved) => (is_resolved ? 'Yes' : 'No'),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div>
          <Button
            type="link"
            onClick={() => openModal(record)}
          >
            View
          </Button>
          {!record.is_resolved && (
            <Button
              type="link"
              onClick={() => handleMarkAsResolved(record._id)}
            >
              Mark as Resolved
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <DashboardHeader
        title="Contact Us Management"
        searchPlaceholder="Search Contacts"
        onSearchChange={(e) => setSearchText(e.target.value)}
      />
      <Table
        dataSource={filteredContacts || []}
        columns={columns}
        rowKey="_id"
        loading={loading}
        pagination={{ pageSize: 10 }}
        bordered
      />

      <Modal
        title="Contact Details"
        open={isModalVisible}
        onCancel={closeModal}
        footer={[
          <Button key="close" onClick={closeModal}>
            Close
          </Button>,
        ]}
      >
        {selectedContact && (
          <div>
            <p><strong>Name:</strong> {selectedContact.name}</p>
            <p><strong>Email:</strong> {selectedContact.email}</p>
            <p><strong>Phone:</strong> {selectedContact.phone || 'N/A'}</p>
            <p><strong>Message:</strong> {selectedContact.message}</p>
            <p><strong>Resolved:</strong> {selectedContact.is_resolved ? 'Yes' : 'No'}</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ContactManagement;
