import React from 'react';
import { Row, Col, Typography, Avatar, Image, Card } from 'antd';
import { TeamOutlined, CompassOutlined, SettingOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const About = () => {
  return (
    <div style={{ padding: '50px', paddingTop: "90px", height: "100vh", overflowY: "scroll" }}>
      <Row justify="center">
        <Col span={18}>
          <Title level={2} style={{ textAlign: 'center', marginBottom: '30px' }}>
            About TourismPk
          </Title>
          <Paragraph style={{ fontSize: '16px', lineHeight: '1.8', textAlign: 'center' }}>
            TourismPk is a platform dedicated to making travel within Pakistan more accessible, enjoyable, and enriching.
            By connecting enthusiastic travelers with skilled local organizers, we aim to simplify the travel experience while showcasing the best of Pakistan’s natural beauty, cultural diversity, and historical heritage.
            Whether you’re a thrill-seeker, a history buff, or simply someone who loves to explore, TourismPk offers tailored solutions to meet your travel needs.
          </Paragraph>
        </Col>
      </Row>

      <Row gutter={[32, 32]} justify="center" style={{ marginTop: '40px' }}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={8}
        >
          <Card
            hoverable
            cover={<Image src="/images/explore.jpg" alt="Explore Destinations" />}
            style={{ textAlign: 'center', border: '1px solid #e8e8e8' }}
          >
            <Avatar size={64} icon={<CompassOutlined />} style={{ backgroundColor: '#52c41a', marginBottom: 10 }} />
            <Title level={4}>Explore Destinations</Title>
            <Paragraph style={{ fontSize: '14px' }}>
              Dive into an array of meticulously curated tours led by experienced organizers. From the serene valleys of
              Swat and Hunza to the bustling metropolises of Karachi and Lahore, our platform offers a wide range of
              options. You can immerse yourself in breathtaking landscapes, discover hidden gems, and experience the
              warmth of local communities as you explore the diverse beauty of Pakistan.
            </Paragraph>
          </Card>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={8}
        >
          <Card
            hoverable
            cover={<Image src="/images/organizers.jpg" alt="Connect with Organizers" />}
            style={{ textAlign: 'center', border: '1px solid #e8e8e8' }}
          >
            <Avatar size={64} icon={<TeamOutlined />} style={{ backgroundColor: '#1890ff', marginBottom: 10 }} />
            <Title level={4}>Connect with Organizers</Title>
            <Paragraph style={{ fontSize: '14px' }}>
              TourismPk bridges the gap between travelers and organizers by offering a seamless connection platform.
              Organizers can showcase their upcoming trips, manage bookings efficiently, and engage with a larger audience.
              Whether you’re a solo traveler or a group, this feature ensures you find the perfect match to make your
              journey memorable and hassle-free.
            </Paragraph>
          </Card>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={8}
        >
          <Card
            hoverable
            cover={<Image src="/images/manage.jpg" alt="Effortless Management" />}
            style={{ textAlign: 'center', border: '1px solid #e8e8e8' }}
          >
            <Avatar size={64} icon={<SettingOutlined />} style={{ backgroundColor: '#faad14', marginBottom: 10 }} />
            <Title level={4}>Effortless Management</Title>
            <Paragraph style={{ fontSize: '14px' }}>
              Our innovative tools are designed to simplify trip management for organizers, enabling them to focus
              on delivering exceptional travel experiences. With features like real-time updates, streamlined booking processes,
              and advanced analytics, managing tours has never been easier. Empower your travel business with TourismPk’s efficient solutions.
            </Paragraph>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default About;
