import React from 'react';
import { Form, Input, Button, message } from 'antd';

const SecurityForm = ({ onFinish, loading }) => {
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={(values) => onFinish(values)}
      onFinishFailed={() => message.error('Please fix the errors before submitting')}
    >
      <Form.Item
        label="Current Password"
        name="currentPassword"
        rules={[{ required: true, message: 'Please enter your current password' }]}
      >
        <Input.Password placeholder="Enter your current password" />
      </Form.Item>
      <Form.Item
        label="New Password"
        name="newPassword"
        rules={[
          { required: true, message: 'Please enter a new password' },
          { min: 8, message: 'Password must be at least 8 characters long' },
          { pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, message: 'Password must contain at least one uppercase letter, one lowercase letter, and one number' },
        ]}
      >
        <Input.Password placeholder="Enter your new password" />
      </Form.Item>
      <Form.Item
        label="Confirm New Password"
        name="confirmPassword"
        dependencies={['newPassword']}
        rules={[
          { required: true, message: 'Please confirm your new password' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Passwords do not match'));
            },
          }),
        ]}
      >
        <Input.Password placeholder="Confirm your new password" />
      </Form.Item>
      <Form.Item style={{ float: "right" }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Update Password
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SecurityForm;
