import axios from 'axios';
import LocalStorageService from './LocalStorage';

const API_URL = process.env.REACT_APP_SERVER_API_URL;


const getOrganizations = async (query = '') => {
  try {
    const response = await axios.get(`${API_URL}/api/organizations`, {
      params: { query },
      headers: {
        Authorization: `Bearer ${LocalStorageService.getItem('token')}`, // Add authorization if required
      },
    });
    return response.data; // Assuming the API response includes a `data` field for organizations
  } catch (error) {
    throw error.response?.data?.message || 'Failed to fetch organizations';
  }
};

const addOrganization = async (organizationData) => {
  try {
    const response = await axios.post(`${API_URL}/api/organizations`, organizationData, {
      headers: {
        Authorization: `Bearer ${LocalStorageService.getItem('token')}`, // Add authorization if required
      },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to create organization';
  }
};

const updateOrganization = async (id, organizationData) => {
  try {
    const response = await axios.put(`${API_URL}/api/organizations/${id}`, organizationData, {
      headers: {
        Authorization: `Bearer ${LocalStorageService.getItem('token')}`, // Add authorization if required
      },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to update organization';
  }
};

const deleteOrganization = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/api/organizations/${id}`, {
      headers: {
        Authorization: `Bearer ${LocalStorageService.getItem('token')}`, // Add authorization if required
      },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to delete organization';
  }
};

const OrganizationsService = {
  getOrganizations,
  addOrganization,
  updateOrganization,
  deleteOrganization,
};
export default OrganizationsService;