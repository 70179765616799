import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
import { Dropdown, Space } from 'antd';
import { DashboardOutlined, LogoutOutlined, SettingOutlined, UserOutlined, HeartOutlined, CalendarOutlined } from '@ant-design/icons';
import { jwtDecode } from 'jwt-decode'; // Correct import

import './UserIcon.css';
import LocalStorageService from '../../services/LocalStorage';

function UserIcon() {
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(null);

  const token = LocalStorageService.getItem('token');

  const handleLogout = useCallback(() => {
    LocalStorageService.logout(navigate);
  }, [navigate]);

  useEffect(() => {
    if (token) {
      try {
        const decodedData = jwtDecode(token);
        setUserRole(decodedData?.data?.role);

        if (decodedData?.data?.verified_email === false) {
          navigate('/verification');
        }
      } catch (error) {
        console.error('Invalid token:', error);
        handleLogout();
      }
    }
  }, [navigate, token, handleLogout]);

  function onClickMenu(val) {
    navigate(val);
  }

  const menuItems = [
    {
      key: '1',
      label: (
        <span>
          <UserOutlined /> My Account
        </span>
      ),
      onClick: () => onClickMenu('/my-account'),
    },
    { type: 'divider' },
    {
      key: '2',
      label: (
        <span>
          <CalendarOutlined /> My Bookings
        </span>
      ),
      onClick: () => onClickMenu('/my-bookings'),
    },
    {
      key: '3',
      label: (
        <span>
          <HeartOutlined /> Favorites
        </span>
      ),
      onClick: () => onClickMenu('/favorites'),
    },

    ...(userRole?.trim().toUpperCase() === 'ADMIN'
      ? [
        {
          key: '4',
          label: (
            <span>
              <DashboardOutlined /> Dashboard
            </span>
          ),
          onClick: () => onClickMenu('/dashboard'),
        },
      ]
      : []),
    {
      key: '5',
      label: (
        <span>
          <SettingOutlined /> Settings
        </span>
      ),
      onClick: () => onClickMenu('/my-account/security'),
    },
    { type: 'divider' },
    {
      key: '6',
      label: (
        <span>
          <LogoutOutlined /> Logout
        </span>
      ),
      onClick: handleLogout,
    },
  ];

  return (
    <div className="user-icon-container">
      {token ? (
        <Dropdown menu={{ items: menuItems }} trigger={['click']}>
          <Space onClick={(e) => e.preventDefault()} className="user-dropdown">
            <FaUserCircle size={30} className="user-icon" />
          </Space>
        </Dropdown>
      ) : null
      }
    </div>
  );
}

export default UserIcon;
