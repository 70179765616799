import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Verification from './pages/auth/Verification/Verification';
import ProtectedRoutes from './utils/ProtectedRoutes';
import Layout from './components/Layout';
import NotFound from './pages/NotFound';
import ResetPassword from './pages/auth/PasswordResetRequest';
import Login from './pages/auth/Login/Login';
import Register from './pages/auth/Register/Register';
import AuthLayout from './pages/auth/Layout';
import Dashboard from './pages/dashboard/Dashboard';
import AdsManagement from './pages/dashboard/ads-management/AdsManagement';
import UserManagement from './pages/dashboard/user-management/UserManagement';
import CitiesManagement from './pages/dashboard/cities-management/CitiesManagement';
import Organization from './pages/dashboard/organizations/Organization';
import Adventure from './pages/adventure/Adventure';
import MyAccount from './pages/my-account/MyAccount';
import General from './pages/my-account/general/General';
import Security from './pages/my-account/security/Security';
import Detail from './pages/adventure/detail/Detail';
import Booking from './pages/booking/Booking';
import BookingManagement from './pages/dashboard/booking-management/BookingManagment';
import ContactUs from './pages/contact-us/ContactUs';
import ContactManagement from './pages/dashboard/contact-us/ContactUs';
import About from './pages/about/About';
import Favorites from './pages/favorites/Favorites';
import MyBookings from './pages/my-bookings/MyBookings';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route element={<Layout />}>
          <Route exact path="/" element={<Home />} />
          <Route path="/adventures" element={<Adventure />} />
          <Route path="/adventures/detail" element={<Detail />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about" element={<About />} />

        </Route>

        {/* Auth Routes */}
        <Route element={<AuthLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>

        {/* Protected Routes */}
        <Route
          element={
            <Layout>
              <ProtectedRoutes />
            </Layout>
          }
        >
          <Route path="/booking" element={<Booking />} />
          <Route path="/favorites" element={<Favorites />} />
          <Route path="/my-bookings" element={<MyBookings />} />


          {/* Add more protected routes as needed */}
        </Route>

        {/* Admin Protected Routes */}
        <Route element={<ProtectedRoutes allowedRoles={['ADMIN']} />}>
          <Route path="/dashboard" element={<Dashboard />}>
            <Route index element={<Navigate to="/dashboard/ads-management" replace />} />
            <Route path="ads-management" element={<AdsManagement />} />
            <Route path="user-management" element={<UserManagement />} />
            <Route path="cities-management" element={<CitiesManagement />} />
            <Route path="organization" element={<Organization />} />
            <Route path="bookings-management" element={<BookingManagement />} />
            <Route path="contact-us" element={<ContactManagement />} />
          </Route>
        </Route>

        {/* Organization/User Protected Routes */}
        <Route element={<ProtectedRoutes allowedRoles={['ORGANIZATION', 'USER', 'ADMIN']} />}>
          <Route path="/my-account/*" element={<MyAccount />}>
            <Route index element={<Navigate to="/my-account/general" replace />} />
            <Route path="general" element={<General />} />
            <Route path="security" element={<Security />} />
          </Route>
        </Route>

        {/* 404 Not Found */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
