import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  Input,
  Button,
  DatePicker,
  InputNumber,
  message,
} from "antd";
import AdsService from "../../../services/AdService"; // Import the Ad API service
import AuthService from "../../../services/AuthService"; // Import the Auth API service
import dayjs from "dayjs";
import PhoneNumberInput from "../../../components/PhoneInput";

const { RangePicker } = DatePicker;
const { Option } = Select;

const BookingForm = ({ initialValues, onFinish, loading }) => {
  const [form] = Form.useForm();
  const [ads, setAds] = useState([]);
  const [loadingAds, setLoadingAds] = useState(false);
  const [isUserSelected, setIsUserSelected] = useState(false);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        ...initialValues,
        adventure_dates: [
          initialValues.start_date && dayjs(initialValues.start_date).isValid()
            ? dayjs(initialValues.start_date)
            : null,
          initialValues.end_date && dayjs(initialValues.end_date).isValid()
            ? dayjs(initialValues.end_date)
            : null,
        ],
        ad: initialValues.ad._id,
        organization: initialValues.organization._id,
      });
    } else {
      form.resetFields();
    }
  }, [initialValues, form]);
  useEffect(() => {
    const fetchAds = async () => {
      setLoadingAds(true);
      try {
        const selectFields = {
          ads: "title price start_date end_date",
          organization: "name",
        };
        const response = await AdsService.getAds(selectFields);
        setAds(response.data || []);
      } catch (error) {
        message.error("Failed to fetch ads");
      } finally {
        setLoadingAds(false);
      }
    };

    fetchAds();
  }, []);

  const handleAdChange = (adId) => {
    const selectedAd = ads.find((ad) => ad._id === adId);
    if (selectedAd) {
      form.setFieldsValue({
        organization: selectedAd.organization._id,
        total_price: selectedAd.price,
        adventure_dates: [
          dayjs(selectedAd.start_date),
          dayjs(selectedAd.end_date),
        ],
      });
    }
  };

  const handleUserChange = async (email) => {
    if (email) {
      try {
        const user = await AuthService.getUserByEmail(email); // Fetch user by email
        if (user) {
          form.setFieldsValue({
            full_name: user.full_name,
            contact_no: user.contact,
          });
          setIsUserSelected(true); // Disable fields when a user is found
          form.setFields([
            {
              name: "full_name",
              rules: [{ required: false }],
            },
            {
              name: "contact_no",
              rules: [{ required: false }],
            },
          ]);
        } else {
          message.error("User not found");
          form.setFieldsValue({
            full_name: "",
            contact_no: "",
          });
          setIsUserSelected(false); // Enable fields when no user is found
          form.setFields([
            {
              name: "full_name",
              rules: [{ required: true, message: "Please enter Full Name" }],
            },
            {
              name: "contact_no",
              rules: [{ required: true, message: "Please enter Contact No." }],
            },
          ]);
        }
      } catch (error) {
        message.error("Failed to fetch user details");
        setIsUserSelected(false);
      }
    } else {
      form.setFieldsValue({
        full_name: "",
        contact_no: "",
      });
      setIsUserSelected(false); // Enable fields when email is cleared
      form.setFields([
        {
          name: "full_name",
          rules: [{ required: true, message: "Please enter Full Name" }],
        },
        {
          name: "contact_no",
          rules: [{ required: true, message: "Please enter Contact No." }],
        },
      ]);
    }
  };

  const handleFinish = (values) => {
    const { adventure_dates, ...rest } = values;
    const [start_date, end_date] = adventure_dates || [];
    onFinish({ ...rest, start_date, end_date });
  };

  const handleValuesChange = (changedValues, allValues) => {
    const { participants, ad } = allValues;
    const selectedAd = ads.find((adItem) => adItem._id === ad);

    if (participants && selectedAd) {
      const basePrice = selectedAd.price;
      const totalPrice = participants * basePrice;
      form.setFieldsValue({ total_price: totalPrice });
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      onFinishFailed={() =>
        message.error("Please correct the errors before submitting")
      }
      onValuesChange={handleValuesChange}
    >
      <Form.Item
        label="Ad"
        name="ad"
        rules={[{ required: true, message: "Please select an Ad" }]}
      >
        <Select
          placeholder="Select an Ad"
          loading={loadingAds}
          onChange={handleAdChange}
        >
          {ads.map((ad) => (
            <Option key={ad._id} value={ad._id}>
              {ad.title}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Organization"
        name="organization"
        rules={[{ required: true, message: "Please select an Organization" }]}
      >
        <Select disabled placeholder="Enter Organization">
          {ads.map((ad) => (
            <Option key={ad.organization._id} value={ad.organization._id}>
              {ad.organization.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Adventure Dates"
        name="adventure_dates"
        rules={[{ required: true, message: "Please select booking dates" }]}
      >
        <RangePicker disabled allowEmpty={[true, true]} />
      </Form.Item>
      <Form.Item
        label="User Email"
        name="user_email"
        rules={[{ required: true, message: "Please enter a User Email" }]}
      >
        <Input
          type="email"
          placeholder="Enter User Email"
          onBlur={(e) => handleUserChange(e.target.value)}
        />
      </Form.Item>
      <Form.Item
        label="Full Name"
        name="full_name"
        rules={[
          { required: !isUserSelected, message: "Please enter Full Name" },
        ]}
      >
        <Input placeholder="Enter Full Name" disabled={isUserSelected} />
      </Form.Item>

      <Form.Item
        label="Phone"
        name="contact_no"
        rules={[
          {
            required: true,
            message: "Phone number is required",
          },
          {
            // pattern: /^\+\d{1,3}(-|\s)?\d{1,4}(-|\s)?\d{1,4}(-|\s)?\d{1,9}$/,
            // message: "Phone number must include country code and valid digits",
          },
        ]}
      >
        <PhoneNumberInput
          value={form.getFieldValue("contact_no") || ""}
          onChange={(phone) => form.setFieldsValue({ contact_no: phone })}
        />
      </Form.Item>

      <Form.Item
        label="Number of Participants"
        name="participants"
        rules={[
          {
            required: true,
            message: "Please enter the number of participants",
          },
        ]}
      >
        <InputNumber
          min={1}
          placeholder="Enter number of participants"
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        label="Total Price"
        name="total_price"
        rules={[{ required: true, message: "Please enter the total price" }]}
      >
        <InputNumber
          min={0}
          placeholder="Enter total price"
          style={{ width: "100%" }}
          prefix="$"
          disabled
        />
      </Form.Item>
      <Form.Item label="Special Requests" name="special_requests">
        <Input.TextArea placeholder="Enter any special requests" rows={4} />
      </Form.Item>
      <Form.Item style={{ float: "right" }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          {initialValues ? "Update Booking" : "Save Booking"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default BookingForm;
