import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Select, message } from "antd";
// import debounce from "lodash.debounce";
import { jwtDecode } from "jwt-decode";
import CitiesService from "../services/CitiesService";
import "./Home.css";
import LocalStorageService from "../services/LocalStorage";

function Home() {
  const navigate = useNavigate();
  const token = LocalStorageService.getItem("token");
  const [cities, setCities] = useState([]);

  const handleLogout = useCallback(() => {
    LocalStorageService.logout(navigate)
  }, [navigate]);

  useEffect(() => {
    if (token) {
      try {
        const decodedData = jwtDecode(token);
        if (decodedData?.data?.verified_email === false) {
          navigate("/verification");
        }
      } catch (error) {
        console.error("Invalid token:", error);
        handleLogout();
      }
    }
  }, [navigate, token, handleLogout]);

  const fetchCities = async () => {
    try {
      const response = await CitiesService.searchAllCities();

      setCities(response.data || []);
    } catch (error) {
      message.error(error || 'Failed to load cities');
      setCities([]);
    }
  };

  // Debounce logic inside useEffect
  // useEffect(() => {
  //   const debouncedFetch = debounce((value) => {
  //     fetchCities(value);
  //   }, 300);

  //   // Call the debounced function when searchValue changes
  //   if (searchValue) {
  //     debouncedFetch(searchValue);
  //   }

  //   // Cleanup the debounce function on unmount
  //   return () => {
  //     debouncedFetch.cancel();
  //   };
  // }, [searchValue, fetchCities]);

  useEffect(() => {
    fetchCities();

  }, []);


  const handleSearchChange = (event) => {
    navigate(`/adventures?search=${encodeURIComponent(event)}`)

  };



  return (
    <div className="home-container">
      <div className="search-container">

        <Select
          showSearch
          optionFilterProp="children"
          allowClear
          placeholder="Where to?"
          onChange={handleSearchChange}
          size="large"
        >

          {cities?.map((city) => (
            <Select.Option key={city._id} value={city._id}>
              {city.name}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  );
}

export default Home;
