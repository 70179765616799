import React from 'react';
import { Timeline, Typography } from 'antd';
import { CheckCircleOutlined, ClockCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const Itinerary = ({ itinerary }) => (
    <div className="itinerary-container" style={{ padding: '2rem 0' }}>
        <Title level={3}>Itinerary</Title>
        <Timeline mode="alternate">
            {itinerary.map((item, index) => {
                let icon;
                let color;
                // Determine icon and color for the start, middle, and end items
                if (index === 0) {
                    icon = <PlayCircleOutlined style={{ fontSize: '16px', color: 'green', }} />; // Success color
                    color = 'success';
                } else if (index === itinerary.length - 1) {
                    icon = <CheckCircleOutlined style={{ fontSize: '16px', color: 'red' }} />; // Danger color
                    color = 'danger';
                } else {
                    icon = <ClockCircleOutlined style={{ fontSize: '16px', color: 'var(--primary-color)' }} />; // Primary color
                    color = 'blue';
                }

                return (
                    <Timeline.Item
                        key={item._id}
                        color={color} // Sets the timeline item color
                        dot={icon} // Sets the custom icon
                    >
                        <Title level={5} style={{ color: 'var(--primary-color)', marginBottom: '0.5rem' }}>
                            Day {item.day} | {item.title}
                        </Title>
                        <Paragraph>{item.activities}</Paragraph>
                    </Timeline.Item>
                );
            })}
        </Timeline>
    </div>
);

export default Itinerary;
