import React, { useEffect, useState } from "react";
import { Typography, Divider, Button, message } from "antd";
import {
  HeartOutlined,
  HeartFilled,
  ShareAltOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import LocalStorageService from "../../../services/LocalStorage";
import AdsService from "../../../services/AdService";
import BookingsService from "../../../services/BookingService";
import CancellationRequest from "./CancellationRequest";

const { Title, Text } = Typography;

const BookingCard = ({
  width,
  price,
  duration,
  startDetails,
  endDetails,
  adId,
  favorite,
  booked,
  booking_id,
  status,
  is_cancelled,
  showModal,
}) => {
  const [isFavorited, setIsFavorited] = useState(favorite);
  const [isCancel, setIsCancel] = useState(false);
  const [isCancelled, setIsCancelled] = useState(is_cancelled);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [reason, setReason] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const token = LocalStorageService.getItem("token");
  const shareUrl = `${window.location.origin}${location.pathname}?search=${adId}`; // Dynamic URL

  useEffect(() => {
    setIsFavorited(favorite); // Update state if the prop changes
    setIsCancelled(is_cancelled)
  }, [favorite,is_cancelled]);

  // Function to handle favorite toggle action
  const handleFavorite = async () => {
    try {
      if (!token) {
        navigate("/login");
        return;
      }
      // Toggle favorite status using a backend endpoint
      await AdsService.toggleFavorite(adId);
      // Toggle the favorite state locally
      setIsFavorited(!isFavorited);
      message.success(
        isFavorited ? "Removed from favorites" : "Added to favorites"
      );
    } catch (error) {
      message.error("Failed to update favorite status");
      console.error("Favorite Error:", error);
    }
  };
  const handleCancelBooking = async () => {
    try {
      try {
        await BookingsService.updateBooking(booking_id, {
          status: "Cancelled",
        });
      } catch (error) {
        message.error("Failed to update status");
      }
      setIsCancel(true);
      message.success("Booking cancelled sucessfully");
    } catch (error) {
      message.error("Failed to update Booking status");
      console.error("Booking Error:", error);
    }
  };

  const handleSystemShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Check out this adventure!",
          text: "I found this great adventure on tourismPk.com:",
          url: shareUrl,
        })
        .then(() => message.success("Successfully shared!"))
        .catch((error) => {
          console.error("Share failed:", error);
          message.error("Failed to share");
        });
    } else {
      message.warning("Sharing not supported on this device");
    }
  };

  const renderDateTime = (details) => (
    <div className="date-time">
      <div>
        <Text style={{ color: "var(--primary-color)" }}>{details?.day}</Text>{" "}
        <Text>{details?.monthYear}</Text>
      </div>
      <Title style={{ color: "var(--primary-color)" }}>{details?.date}</Title>
      <Text>{details?.time}</Text>
    </div>
  );
  const renderDuration = (durationText) => {
    const parts = durationText.split(" ");
    return (
      <Text>
        <span
          style={{
            fontWeight: "bold",
            fontSize: "1.5rem",
            color: "var(--primary-color)",
          }}
        >
          {parts[0]}
        </span>{" "}
        {parts[1]}{" "}
        <span
          style={{
            fontWeight: "bold",
            fontSize: "1.5rem",
            color: "var(--primary-color)",
          }}
        >
          {parts[2]}
        </span>{" "}
        {parts[3]}
      </Text>
    );
  };
  const handleRequestCancellation = () => {
    if (!token) {
      navigate("/login");
      return;
    }
    
    
    if(isCancelled){
      return message.error("You have already submitted a cancellation request.");
    }
    setIsModalVisible(true);
  };
  const handleSubmitReason = async (reason) => {
    try {
      await BookingsService.updateBooking(booking_id, {
        cancellation_reason: reason,
        is_cancelled:true
      });
      setIsModalVisible(false);
      message.success("Cancellation request submitted successfully.");
    } catch (error) {
      message.error("Failed to submit cancellation request.");
      console.error("Cancellation Error:", error);
    }
  };

  const handleCancelModal = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <div className={`booking-card ${width <= 990 ? "" : "br"}`}>
        <div className="f-b">
          <Title level={3}>PKR {price.toLocaleString()}</Title>
          <div>
            <span onClick={handleFavorite} style={{ cursor: "pointer" }}>
              {isFavorited ? (
                <HeartFilled style={{ fontSize: "24px", color: "red" }} />
              ) : (
                <HeartOutlined style={{ fontSize: "24px" }} />
              )}
            </span>
            <ShareAltOutlined
              style={{
                fontSize: "24px",
                cursor: "pointer",
                marginLeft: "10px",
              }}
              onClick={handleSystemShare}
            />
          </div>
        </div>

        <Divider />

        <div className="other-info-card">
          {renderDuration(duration)}
          <br />
        </div>

        <div className="date-card">
          {renderDateTime(startDetails)} ~ {renderDateTime(endDetails)}
        </div>
      </div>

      {status === "Rejected" ? (
        <Button
          type="primary"
          block
          className={`booking-btn ${width <= 990 ? "sticky-btn" : null}`}
          style={{ background: "grey", cursor: "not-allowed" }}
          disabled
        >
          Rejected
        </Button>
      ) : status === "Accepted" ? (
        <Button
          type="primary"
          block
          className={`booking-btn ${width <= 990 ? "sticky-btn" : null}`}
          style={{ background: "orange" }}
          onClick={handleRequestCancellation}
        >
          Request for Cancellation
        </Button>
      ) : booked && !isCancel ? (
        <Button
          type="primary"
          block
          className={`booking-btn ${width <= 990 ? "sticky-btn" : null}`}
          style={{ background: "red" }}
          onClick={() => {
            if (!token) {
              navigate("/login");
              return;
            }
            handleCancelBooking();
          }}
        >
          Cancel Booking
        </Button>
      ) : (
        <Button
          type="primary"
          block
          className={`booking-btn ${width <= 990 ? "sticky-btn" : null}`}
          onClick={() => {
            if (!token) {
              navigate("/login");
              return;
            }
            showModal();
          }}
        >
          Book Now
        </Button>
      )}
      <CancellationRequest
        isVisible={isModalVisible}
        onClose={handleCancelModal}
        onSubmit={handleSubmitReason}
        reason={reason}
        setReason={setReason}
      />
    </div>
  );
};

export default BookingCard;
