// src/constants/Constant.js

import React from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Space, Popconfirm, Tag, Switch, message, Select } from 'antd'
import dayjs from 'dayjs';
import BookingsService from '../../services/BookingService';
import { Option } from 'antd/es/mentions';

export const USER_COLUMNS = (openModal, handleDeleteUser) => [
  {
    title: 'Name',
    dataIndex: 'full_name',
    key: 'full_name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (_, record) => (
      <Space size="middle">
        <Button icon={<EditOutlined />} type="link" onClick={() => openModal(record)} />
        <Popconfirm
          title="Delete User"
          description="Are you sure to delete this user?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => handleDeleteUser(record._id)}
        >
          <Button danger icon={<DeleteOutlined />} type="link" />
        </Popconfirm>
      </Space>
    ),
  },
];

export const AD_COLUMNS = (fetchAdDetail, handleDeleteAd, colors) => [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Duration",
    dataIndex: "duration",
    key: "duration",
  },
  {
    title: "Cities Covered",
    dataIndex: "cities_covered",
    key: "cities_covered",
    render: (cities) => (
      <>
        {cities?.map((city, index) => (
          <Tag style={{ margin: "4px" }} color={colors[index % colors.length]} key={city._id}>
            {city.name}
          </Tag>
        ))}
      </>
    ),
  },
  {
    title: "Posted By",
    dataIndex: "organization",
    key: "organization",
    render: (organization) => (
      <Button type="link">{organization?.name}</Button>
    ),
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    key: "start_date",
    render: (date) => dayjs(date).format("D MMM YYYY"),
  },
  {
    title: "End Date",
    dataIndex: "end_date",
    key: "end_date",
    render: (date) => dayjs(date).format("D MMM YYYY"),
  },
  {
    title: "Actions",
    key: "actions",
    render: (_, record) => (
      <Space size="middle">
        <Button
          icon={<EditOutlined />}
          type="link"
          onClick={() => fetchAdDetail(record._id)}
        />
        <Popconfirm
          title="Are you sure you want to delete this ad?"
          onConfirm={() => handleDeleteAd(record._id)}
          okText="Yes"
          cancelText="No"
        >
          <Button danger icon={<DeleteOutlined />} type="link" />
        </Popconfirm>
      </Space>
    ),
  },
];

export const ORGANIZATION_COLUMNS = (openModal, handleDeleteOrganization) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'contact_email',
    key: 'contact_email',
  },
  {
    title: 'Phone',
    dataIndex: 'contact_phone',
    key: 'contact_phone',
  },
  {
    title: 'Website',
    dataIndex: 'website',
    key: 'website',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (_, record) => (
      <Space size="middle">
        <Button
          icon={<EditOutlined />}
          type="link"
          onClick={() => openModal(record)}
        />
        <Popconfirm
          title="Are you sure you want to delete this organization?"
          onConfirm={() => handleDeleteOrganization(record._id)}
          okText="Yes"
          cancelText="No"
        >
          <Button danger icon={<DeleteOutlined />} type="link" />
        </Popconfirm>
      </Space>
    ),
  },
];


export const BOOKING_COLUMNS = (openModal, handleDeleteBooking, fetchBookings,loadingId, setLoadingId, BookingService ) => [
  {
    title: 'Ad',
    dataIndex: 'ad',
    key: 'ad',
    render: (ad) => `${ad?.title}`
  },
  {
    title: 'User',
    dataIndex: 'full_name',
    key: 'full_name',
  },
  {
    title: 'Email',
    dataIndex: 'user_email',
    key: 'user_email',
  },
  {
    title: 'Contact',
    dataIndex: 'contact_no',
    key: 'contact_no',
  },
  {
    title: 'Organization',
    dataIndex: 'organization',
    key: 'organization',
    render: (organization) => (
      <Button type="link">{organization?.name}</Button>
    ),
  },
  {
    title: 'Booking Dates',
    dataIndex: 'adventure_dates',
    key: 'adventure_dates',
    render: (_, record) => `${dayjs(record.start_date).format("D MMM YYYY")} - ${dayjs(record.end_date).format("D MMM YYYY")}`,
  },
  {
    title: 'Participants',
    dataIndex: 'participants',
    key: 'participants',
  },
  {
    title: 'Total Price',
    dataIndex: 'total_price',
    key: 'total_price',
    render: (price) => `PKR ${price}`,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status, record) =>
      status === 'Cancelled' ? (
        <span style={{ color: 'red', fontWeight: 'bold' }}>Cancelled</span>
      ) : (
        <Select
          defaultValue={status}
          style={{ width: 120 }}
          onChange={async (newStatus) => {
            try {
              await BookingsService.updateBooking(record._id, { status: newStatus });
              message.success('Status updated successfully');
            } catch (error) {
              message.error('Failed to update status');
            }
          }}
        >
          <Option value="Open">Open</Option>
          <Option value="Accepted">Accept</Option>
          <Option value="Rejected">Reject</Option>
        </Select>
      ),
  },
  {
    title: 'Cancellation Request',
    key: 'cancellation_request',
    render: (_, record) => {
      const { cancellation_status, cancellation_reason } = record;

      if (cancellation_status === 'Open') {
        if (!cancellation_reason) {
          return <span>Not Received</span>;
        }

        return (
          <Space>
            <Button
              type="primary"
              loading={loadingId === `${record._id}_approve`} // Check if "Approve" button is loading
              onClick={async () => {
                try {
                  setLoadingId(`${record._id}_approve`); // Set loading ID for Approve
                  await BookingService.updateBooking(record._id, {
                    cancellation_status: 'Approved',
                    status: 'Cancelled',
                  });
                  message.success('Cancellation approved successfully');
                  await fetchBookings(); // Refresh bookings
                } catch (error) {
                  message.error('Failed to approve cancellation');
                } finally {
                  setLoadingId(null); // Clear loading ID
                }
              }}
            >
              Approve
            </Button>
            <Button
              type="danger"
              loading={loadingId === `${record._id}_reject`} // Check if "Reject" button is loading
              onClick={async () => {
                try {
                  setLoadingId(`${record._id}_reject`); // Set loading ID for Reject
                  await BookingService.updateBooking(record._id, {
                    cancellation_status: 'Rejected',
                  });
                  message.success('Cancellation rejected successfully');
                  await fetchBookings(); // Refresh bookings
                } catch (error) {
                  message.error('Failed to reject cancellation');
                } finally {
                  setLoadingId(null); // Clear loading ID
                }
              }}
            >
              Reject
            </Button>
          </Space>
        );
      }

      return <span>{cancellation_status}</span>;
    },
  },
  
  
  
  
  {
    title: 'Actions',
    key: 'actions',
    render: (_, record) => (
      <Space size="middle">
        <Button
          icon={<EditOutlined />}
          type="link"
          onClick={() => openModal(record)}
        />
        <Popconfirm
          title="Are you sure you want to delete this booking?"
          onConfirm={() => handleDeleteBooking(record._id)}
          okText="Yes"
          cancelText="No"
        >
          <Button danger icon={<DeleteOutlined />} type="link" />
        </Popconfirm>
      </Space>
    ),
  },
];




export const CITY_COLUMNS = (openModal, handleDeleteCity, handleToggleStatus) => [
  {
    title: 'City Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
  },
  {
    title: 'Province',
    dataIndex: 'province',
    key: 'province',
  },
  {
    title: 'Division',
    dataIndex: 'division',
    key: 'division',
  },
  {
    title: 'District',
    dataIndex: 'district',
    key: 'district',
  },
  {
    title: 'Status',
    dataIndex: 'is_active',
    key: 'is_active',
    render: (isActive, record) => (
      <Switch
        checked={isActive}
        onChange={() => handleToggleStatus(record)}
        checkedChildren="Active"
        unCheckedChildren="Inactive"
      />
    ),
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (_, record) => (
      <Space size="middle">
        <Button
          icon={<EditOutlined />}
          type="link"
          onClick={() => openModal(record)}
        />
        <Popconfirm
          title="Delete City"
          description="Are you sure to delete this city?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => handleDeleteCity(record._id)}
        >
          <Button danger icon={<DeleteOutlined />} type="link" />
        </Popconfirm>
      </Space>
    ),
  },
];
