import React from "react";
import { Modal, Input, Button, message } from "antd";

const { TextArea } = Input;

const CancellationRequest = ({
  isVisible,
  onClose,
  onSubmit,
  reason,
  setReason,
}) => {
  const handleSubmit = () => {
    if (!reason) {
      message.warning("Please provide a reason for cancellation.");
      return;
    }
    onSubmit(reason);
  };

  return (
    <Modal
      title="Request for Cancellation"
      visible={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>,
      ]}
    >
      <TextArea
        rows={4}
        placeholder="Please provide a reason for cancellation"
        value={reason}
        onChange={(e) => setReason(e.target.value)}
      />
    </Modal>
  );
};

export default CancellationRequest;
