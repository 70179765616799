import React, { useState, useEffect } from "react";
import { Select, Input } from "antd";
import { countries } from "../utils/countryUtils";

const { Option } = Select;

const PhoneNumberInput = ({
  value,
  onChange,
  defaultCountry = countries.find((c) => c.iso2 === "PK") || countries[0],
  style,
}) => {
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (value) {
      const [phone_code, ...phoneParts] = value.split(" ");
      const initialCountry =
        countries.find((c) => c.phone_code === phone_code) || defaultCountry;
      setSelectedCountry(initialCountry);
      setPhoneNumber(phoneParts.join(" "));
    }
  }, [value, defaultCountry]);

  const handleCountryChange = (phone_code) => {
    const country = countries.find((c) => c.phone_code === phone_code);

    if (!country) {
      console.error(`Country with phone code ${phone_code} not found.`);
      return;
    }

    setSelectedCountry(country);
    setPhoneNumber(""); // Reset phone number when country changes
    onChange(`${country.phone_code}`);
  };

  const handlePhoneChange = (e) => {
    const rawInput = e.target.value.replace(/[^\d]/g, "");
    const mask = selectedCountry?.phone_mask || "##########"; // Fallback mask
    const formattedNumber = formatPhoneNumber(rawInput, mask);
    setPhoneNumber(formattedNumber);
    onChange(`${selectedCountry?.phone_code} ${formattedNumber}`); // Pass full number
  };

  const formatPhoneNumber = (input, mask) => {
    if (!mask) return input;
    let formatted = "";
    let inputIndex = 0;

    for (const char of mask) {
      if (char === "#") {
        if (input[inputIndex]) {
          formatted += input[inputIndex];
          inputIndex++;
        } else {
          break;
        }
      } else {
        formatted += char;
      }
    }

    return formatted;
  };

  if (!countries || countries.length === 0) {
    console.error(
      "No countries found. Ensure the JSON file is correctly loaded."
    );
    return <div>Error: No country data available</div>;
  }

  return (
    <div
      style={{ display: "flex", gap: "8px", alignItems: "center", ...style }}
    >
      <Select
        showSearch
        value={selectedCountry?.phone_code}
        onChange={handleCountryChange}
        style={{ width: "125px" }}
        placeholder="Select a country"
        optionFilterProp="label"
        filterOption={(input, option) =>
          option?.label?.toLowerCase().includes(input.toLowerCase())
        }
        aria-label="Select Country"
      >
        {countries.map((country) => (
          <Option
            key={country.iso2}
            value={country.phone_code}
            label={`${country.emoji} ${country.name} (${country.phone_code})`}
          >
            {country.emoji} ({country.phone_code})
          </Option>
        ))}
      </Select>

      <Input
        value={phoneNumber}
        onChange={handlePhoneChange}
        placeholder={`Enter phone (${selectedCountry?.phone_mask || "##########"
          })`}
        style={{ flex: 1 }}
        aria-label="Phone Number Input"
      />
    </div>
  );
};

export default PhoneNumberInput;
